import React from "react";
//import * as ReactDOMServer from 'react-dom/server';

import { useEffect,  useState, useContext, useCallback, useRef } from 'react';

import _ from 'lodash';

import { useParams,
        useNavigate, useLocation } from 'react-router-dom';

import { strings } from "./../../services/Localization";

import { checkLogin } from "./../../services/Login";
import { dialog, dialogDescription } from '../../components/Common';
import { TAB_ADD_DEVICE, TAB_DEVICE_DETAIL, TAB_VIEW_DATA, TAB_SEND_TO_DEVICE , TAB_APP_ASSIGNMENT, TAB_GROUP_ASSIGNMENT, TAB_PROVISION_DEVICES } from '../../datatypes/tabsconstants'

import { dateTimeString, getVisibleActions, actionIcon, elipsis, prioritizedTableColumns } from '../../utils/filters';


import { faEdit, faDatabase, faPaperPlane, faTrashCan, faAdd, faRefresh,
         faList, faExclamationTriangle,
         faFileImport,
         faFileExport,
        } from '@fortawesome/pro-regular-svg-icons'

import { faExclamationTriangle as solidfaExclamationTriangle, faGridRound, faGridRound5, faGridRound4, faEllipsis, faClock }  from '@fortawesome/pro-solid-svg-icons';

import { IUser } from "src/dassTypes";

import AppContext from '../../context/AppContext'

import { BreadCrumbType, PageButtonType, ServiceProgress } from '../../datatypes/datatypes';

import { ActionType, BulkActionType, ColumnType, DataTableOption } from '../../components/Common/DataTable/DataTypes';

import PayloadExportModal from '../../components/Device/PayloadExportModal';

import { GenericDassQuery } from "../../services/BasicDassQueries";

import { toast } from "./../../utils/Toaster";

import{ CONNECTION_STATUS_OPTIONS, getAppBase, OPERATIONAL_STATUS_OPTIONS, UUIDCOLWIDTH }  from "../../utils/consts"

import{  DEFAULT_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, ID_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";

import PageContent from "../PageContent";
import {  faListCheck } from "@fortawesome/free-solid-svg-icons";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
import { ShowSchemaAsModal } from "../../components/SchemaModal/SchemaModal";

import { trustedFormatText } from "../../schemaengine/client/SchemaTextParser";
import { IJsonSchemaObject } from "../../schemaengine/UiJsonSchemaTypes";
import {  ListGroup } from "react-bootstrap";
import Popover from 'react-bootstrap/Popover';
import Stack from 'react-bootstrap/Stack';
import { HoverOverlay } from "../../schemaengine/client/HoverOverlay";
import Image from 'react-bootstrap/Image';
import last_connected from '../../../resources/images/last_connected.svg';
import last_disconnected from '../../../resources/images/last_disconnected.svg';
import { deviceData, emptyDeviceData, DataTableContext } from "../../components/Common/DataTable/DataTableState";
import { isMobile } from "react-device-detect";



export interface Service {
    name: string;
    buttonText: string;
    show_on_ui?: boolean;
    confirm?: boolean;
    has_parameters?: boolean;
    title: string;
    deviceUuid?: string;
    parametersSchemaArr?: any[];
    parametersSchema?: any
}



export interface IRowType {
    deviceUuid: string;
    deviceId?: string
    description: string;
    name: string;
    useridx?: string;
    connection_uuid?: string;
    device_profile_uuid?: string;
    activated?: boolean;
    suspended?: boolean;
    device_profile_compile_failed?: boolean;
    connection_profile_compile_failed?: boolean;
    device_status?: number;
    image_url?: string;

    services: Array<Service>;

    state: any;
    stateSchema: IJsonSchemaObject;

    groups?: string;
    global_tags?: string;
    applications?: string;
    last_update_tmst?: string;
    last_connected_tmst?: string;
    last_disconnected_tmst?: string;
    remote_ip_address?: string;
    ownerid?: string;
    operationalStatus?: string;
    operationalStatusMessage?: string;
    connectionStatus?: 'disconnected' | 'connected' | 'never_seen' | 'error';
    connectionStatusErrorMessage: string;

    orbiwan_instance?: string;
}




interface IMyDevicesStates {
    loggedUser: IUser | null;
    editDeviceDeveui: string;
    editDeviceIsLegacy: boolean;
    addDevice: boolean;
    showAlertModal: boolean;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    deviceUuid: string;
    selectedAction:string;
    showLoadingDialog:boolean;
    showConfirmDialog:boolean;
    dataTableView: "Basic" | "Detailed" | "Expanded";
    confirmDialogeSettings : {
        title:string;
        description:string;
        actionLabel:string;
        confirmAction: () => void;
    },
    refresh:boolean;
    exportInit:number;
    isRowDeleted?:boolean;
}

const  apps = [];

const basicViewColumns = ['bulk_action_checkbox', 'name', 'image_url', 'operationalStatus', 'state', 'description', 'device_profile_uuid', 'last_update_tmst', 'action_button']

const advancedViewColumns = ['bulk_action_checkbox', "deviceUuid", 'name', 'image_url', 'operationalStatus', 'connectionStatus', 'state', 'description', 'groups', 'global_tags',  'applications', 'last_update_tmst', 'action_button'];

export function getConnectionStatusIcon(row: IRowType, fromTableRow?: boolean) {
    const connectionStatus = row.connectionStatus && row.connectionStatus.toLowerCase();
    const style = fromTableRow ? { marginTop: "0px" } : {};
    switch (connectionStatus) {
        case "connected":   
            return <span style={style} className="connection-status green">{connectionStatus}</span>
        case "disconnected":
            return <span style={style} className="connection-status orange">{connectionStatus}</span>
        case "error":
            return <span style={style} className="connection-status red">{connectionStatus}</span>
        default:
            return <span style={style} className="connection-status gray">{strings.LAST_UPDATE_NOT_SEEN}</span>
    }

}

export function getLedIcon(row: IRowType, returnClassName?: boolean) {
    if(row.suspended === true) {
        return returnClassName ? 'opearational-status-dark-gray' : trustedFormatText("[[fa-solid fa-circle opearational-status-dark-gray]]", row.deviceUuid, null, null);
    } else if(row.device_profile_compile_failed || row.connection_profile_compile_failed) {
        return returnClassName ? 'opearational-status-dark-red' : trustedFormatText("[[fa-solid fa-exclamation-triangle opearational-status-dark-red]]", row.deviceUuid, null, null);
    } else if(row.operationalStatus == 'na' && (row.connectionStatus == 'connected' || row.connectionStatus == 'disconnected')) {
        return returnClassName ? 'opearational-status-green' : trustedFormatText("[[fa-solid fa-circle opearational-status-light-green]]", row.deviceUuid, null, null);
    } else if(row.operationalStatus == 'na' && row.connectionStatus == 'error') {
        return returnClassName ? 'opearational-status-red' : trustedFormatText("[[fa-solid fa-times-circle opearational-status-red]]", row.deviceUuid, null, null);
    } else {
        const operationalStatus = row.operationalStatus && row.operationalStatus.toLowerCase();
        switch (operationalStatus) {
            case "warning":
                return returnClassName ? 'opearational-status-yellow' : trustedFormatText("[[fa-solid fa-exclamation-circle opearational-status-yellow]]", row.deviceUuid, null, null)
            case "error":
                return returnClassName ? 'opearational-status-red' : trustedFormatText("[[ fa-solid fa-times-circle opearational-status-red]]", row.deviceUuid, null, null);
            case "critical":
                return returnClassName ? 'opearational-status-dark-red' : trustedFormatText("[[fa-solid fa-exclamation-triangle opearational-status-dark-red]]", row.deviceUuid, null, null);
                
            case "normal":
                return returnClassName ? 'opearational-status-green' : trustedFormatText("[[fa-solid fa-circle opearational-status-green]]", row.deviceUuid, null, null);
            case "na":
            default:
                return returnClassName ? 'opearational-status-light-gray' : trustedFormatText("[[fa-solid fa-circle opearational-status-light-gray]]", row.deviceUuid, null, null);
        }
    }
    
}

export function renderHealthTooltip(row: IRowType) {
    if(row.operationalStatusMessage) {
        return row.operationalStatusMessage;
    } else if (row.device_profile_compile_failed) {
        return strings.OPERATIONAL_STATUS_MESSAGE_DEVICE_PROFILE_EVENT_HANDLER_FAILED;
    } else if (row.connection_profile_compile_failed) { 
        return strings.OPERATIONAL_STATUS_MESSAGE_CONNECTION_PROFILE_EVENT_HANDLER_FAILED;
    } else if(row.suspended === true) {
        return strings.OPERATIONAL_STATUS_OPTION_SUSPENDED;
    } else if (row.operationalStatus == 'na' && (row.connectionStatus == 'connected' || row.connectionStatus == 'disconnected')) {
        return strings.OPERATIONAL_STATUS_OPTION_CONNECTING;
    } else if(row.operationalStatus == 'na' && row.connectionStatus == 'error') {
        return row.connectionStatusErrorMessage || strings.OPERATIONAL_STATUS_OPTION_ERROR;
    }
    // return the label value from OPERATIONAL_STATUS_OPTIONS, If not found then return the value itself
    return OPERATIONAL_STATUS_OPTIONS.find((x) => x.value === row.operationalStatus)?.label || row.operationalStatus;
    
}

function findServicesFromAllRows(rows, rowService) {

    for (const row of rows) {
        if (row.services && row.services.length > 0) {
            for(const service of row.services) {
                if(rowService.name === service.name) {
                    const isEmptyObjectPresent = rowService.parametersSchemaArr.some(obj => Object.keys(obj).length === 0 && obj.constructor === Object);
                    if(!service.parametersSchema && 
                        !isEmptyObjectPresent) {
                            rowService.parametersSchemaArr.push({})
                    } else if(service.parametersSchema && rowService.parametersSchema && (JSON.stringify(service.parametersSchema) !== JSON.stringify(rowService.parametersSchema))) {
                        rowService.parametersSchemaArr.push(JSON.parse(JSON.stringify(service.parametersSchema)))
                    }
                }
            }
        }
        
    }
    return rowService;
}


const findService = (rows: IRowType[], serviceName: string) => {
    for(const row of rows) {
        if(row.services && row.services.length > 0) {
            for(const index in row.services) {
                if(row.services[index].name === serviceName) {
                    return {
                        services: row.services,
                        index : index,
                        row: row
                    }
                }
            }
        }
    }
    return null;
}



export const execService = async (row: IRowType[], entry: number, isBulk: boolean, isGroup?: boolean, setServiceProgress?: any, serviceProgressRef?: any, serviceName?: string) => {
    
    let services: Service[] = (row[0]?.services || []).filter(s => s.show_on_ui);
    let foundService = findService(row, serviceName);
    let rowDetail: any = row[0];
    if(foundService) {
        entry = parseInt(foundService.index);
        services = foundService.services;
        rowDetail = foundService.row;
    }
    let serviceWithEntry: Service = services[entry];
    let servicesToBeExecuted = [];
    // for bulk devices service execution
    if(isBulk && !isGroup && serviceWithEntry && serviceWithEntry.parametersSchema) {
        serviceWithEntry.parametersSchemaArr = [JSON.parse(JSON.stringify(serviceWithEntry.parametersSchema))];
        serviceWithEntry = findServicesFromAllRows(row, JSON.parse(JSON.stringify(serviceWithEntry)))
        const copyServiceParametersSchemaArr = JSON.parse(JSON.stringify(serviceWithEntry.parametersSchemaArr))
        
            for (const r of row) {
                if (r.services && r.services.length > 0) {
                    for(const s of r.services) {
                        for(let index in copyServiceParametersSchemaArr) {
                            if( ((JSON.stringify(copyServiceParametersSchemaArr[index]) == JSON.stringify(s.parametersSchema)) || (!s.parametersSchema && 
                                JSON.stringify(copyServiceParametersSchemaArr[index]) == "{}")) &&
                                s.name === serviceWithEntry.name){
                                    
                                    if(serviceWithEntry.parametersSchemaArr[index].deviceUuid) {
                                        serviceWithEntry.parametersSchemaArr[index].deviceUuid += `,${r.deviceUuid}`
                                    } else {
                                        serviceWithEntry.parametersSchemaArr[index].deviceUuid = r.deviceUuid
                                    }
                                }
                            }
                        }

                    }
                }
    } else if(isBulk && isGroup && row.length > 1 && serviceWithEntry) { // for bulk group service execution
        services.forEach((service, index) => {
            if(index != entry && service.name == serviceWithEntry.name) {
                if(serviceWithEntry.parametersSchemaArr && serviceWithEntry.parametersSchemaArr.length > 0) {
                    if(service.parametersSchemaArr && service.parametersSchemaArr.length > 0) {
                        for(let paramSchemaArr of service.parametersSchemaArr) {
                            serviceWithEntry.parametersSchemaArr.push(paramSchemaArr)
                        }
                    } else if(service.parametersSchema) {
                        serviceWithEntry.parametersSchemaArr.push(service.parametersSchema)
                    }
                    
                } else if(serviceWithEntry.parametersSchema && service.parametersSchemaArr && service.parametersSchemaArr.length > 0) {
                    serviceWithEntry.parametersSchemaArr = [serviceWithEntry.parametersSchema]
                    for(let paramSchemaArr of service.parametersSchemaArr) {
                        serviceWithEntry.parametersSchemaArr.push(paramSchemaArr)
                    }
                } else if(service.parametersSchema && serviceWithEntry.parametersSchema) {
                    serviceWithEntry.parametersSchemaArr = [serviceWithEntry.parametersSchema , service.parametersSchema];
                }
            }
        })
    }

    if((isBulk || isGroup) && serviceWithEntry && serviceWithEntry.parametersSchemaArr && serviceWithEntry.parametersSchemaArr.length > 0) {
        for(let parametersSchema of serviceWithEntry.parametersSchemaArr) {
            if(parametersSchema) {
                let newServiceWithEntry = JSON.parse(JSON.stringify(serviceWithEntry));
                newServiceWithEntry.parametersSchema = JSON.parse(JSON.stringify(parametersSchema));
                servicesToBeExecuted.push(newServiceWithEntry)
            }
        }
    } else {
        servicesToBeExecuted.push(serviceWithEntry)
    }

    // Execute each service
    for (let service of servicesToBeExecuted) {
        let requestBody = {};
        let deviceUuids;
        if (service && service.parametersSchema && service.parametersSchema.type) {
            try {
                let schemaResponse;
                // If the service is a group service, get the schema from the group
                if (isBulk || isGroup) {
                    // If the Group has multiple devices, and device has services with same name but different parameters, show the schema as modal as per the device associated with service
                    let deviceUuid = (service.parametersSchema && service.parametersSchema.deviceUuid) ? service.parametersSchema.deviceUuid.split(',')[0] : "";
                    if(deviceUuid && servicesToBeExecuted.length >= 1) { 
                        schemaResponse = await ShowSchemaAsModal("/rest/nodes/" + deviceUuid + "/services/" + service.name + "?uischema=true", true)
                        if(service.parametersSchema && service.parametersSchema.deviceUuid) {
                            deviceUuids = service.parametersSchema.deviceUuid;
                        }
                    } else {
                        // If the Group has multiple devices, and device has services are same for all
                        schemaResponse = await ShowSchemaAsModal("/rest/nodes/@" + rowDetail.groupid + "/services/" + service.name + "?uischema=true", true)
                    }
                } else {
                    // If the service is not a group service, get the schema from the device
                    schemaResponse = await ShowSchemaAsModal("/rest/nodes/" + rowDetail.deviceUuid + "/services/" + service.name + "?uischema=true", true)

                }
                if (schemaResponse) {
                    requestBody = schemaResponse;
                } else {
                    continue;
                }

            } catch (e) {
                toast.error(e.message);
            }
        } else if(service.parametersSchema && service.parametersSchema.deviceUuid) {
            deviceUuids = service.parametersSchema.deviceUuid;
        }

        if (service.confirm) {
            const confirmDialogeSettings = {
                title: `${strings.EXECUTE_SERVICE_TITLE} "${service.name}"`,
                description:  ((isBulk) ? "" : `${strings.FOR_DEVICE}"${(rowDetail.name || rowDetail.deviceUuid)}"?`),
                actionLabel: "execute",
                faIcon: faExclamationTriangle
            };
            if (await dialog(confirmDialogeSettings) !== true) { return }
        }

        if (isBulk) {
            await execBulkService(row, entry, service, requestBody, isGroup, setServiceProgress, serviceProgressRef, deviceUuids);
        } else {
            try {
                await singleDeviceExecService(rowDetail, entry, service, requestBody);
            } catch(e) {
                console.log('singleDeviceExecService ', e.message)
            }
            
        }
    }
}

const findCommonServices = (rows) => {

    // Return an empty array if no rows are provided
    if (!rows || rows.length === 0) return [];

    // Get services from the first row
    const services = rows[0].services || [];
    const commonServices = [];

    if (rows.length > 1) {
        for (const service of services) {
            let serviceFoundInAllRows = true;  // Assume service is found in all rows

            for (const row of rows) {
                const rowService = row.services?.find(s => s.name === service.name);

                if (!rowService) {
                    // Service not found in this row
                    serviceFoundInAllRows = false;
                    break;  // No need to check other rows if one is missing the service
                }

                // Check if parametersSchema mismatch
                if (service.parametersSchema && rowService.parametersSchema &&
                    JSON.stringify(service.parametersSchema) !== JSON.stringify(rowService.parametersSchema)) {
                    serviceFoundInAllRows = false;
                    break;  // No need to continue if schemas don't match
                }
            }

            if (serviceFoundInAllRows) {
                // Service is common across all rows
                commonServices.push(service);
            }
        }
    } else {
        // If only one row, all services are common
        return services;
    }

    return commonServices;
};

const visibleScheduleService = (rows) => {
    const commonServices = findCommonServices(rows)
    if(commonServices.length > 0) {
        return true
    } else {
        return false
    }
}

export const execScheduleService = async (rows: IRowType[], entry: number) => {

    const commonServices = findCommonServices(rows);
        
    const serviceNames = commonServices.map(s => s.name).join(",");

    const schemaResponse = await ShowSchemaAsModal("/rest/jobs" + `?deviceUuid=${rows[0]?.deviceUuid}&services=${serviceNames}&get_schema=dassui,post`, true)

    const requestBody: any = schemaResponse;

    const serviceName = requestBody?.serviceName;
    if (requestBody?.parameter) {
        Object.assign(requestBody, requestBody.parameter);
        delete requestBody.parameter;
    }
    delete requestBody?.serviceName;
    if (serviceName) {
        for(const row of rows) {
            const service = row.services.find(s => s.name === serviceName);
            if (service) {
                try {
                    await singleDeviceExecService(row, entry, service, requestBody);
                } catch(e) {
                    console.log('execScheduleService ', e.message)
                    return;
                }
            }
        }
        toast.success(strings.DEVICE_SERVICE_SCHEDULED_SUCCESS);
    }
}

const singleDeviceExecService = async (row: IRowType, entry: number, service: Service, requestBody: any) => {

    try {
        const response = await GenericDassQuery("/rest/nodes/" + row.deviceUuid + "/services/" + service.name, {
            method: "POST",
            data: requestBody 
        });
        if(response?.data?.job_uuid) {
            return;
        }else if (response?.data?.results?.[0]?.statusCode && response.data.results[0].statusCode >= 200 && response.data.results[0].statusCode <= 299) {
            toast.success(strings.DEVICE_SERVICE_EXECUTE_SUCCESS);
        } else {
            if (response?.data?.results?.[0]?.error) {
                toast.error(strings.DEVICE_SERVICE_EXECUTE_ERROR + ' ' + response?.data?.results?.[0]?.error);
            } else {
                toast.error(strings.DEVICE_SERVICE_EXECUTE_ERROR_LIVELOG);
            }
        }
    } catch (e) {
        toast.error(e.message);
        throw new Error(e);
    }

}

const setTimeoutInSeconds = (second: number) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true)
        }, second * 1000)
    })
}

const execBulkService = async (rows: any[], entry: number, service: Service, requestBody: any, isGroup: boolean, setServiceProgress: any, serviceProgressRef: any, deviceUuids: string) => {
    try {
        setServiceProgress({
            totalDevices: 0,
            totalBatches: 1,
            currentBatch: 1,
            passedCount: 0,
            failedCount: 0,
            warning: 10,
            passed: 10,
            failed: 10,
        })
        let servicesToBeExecOnDeviceStr = ""
        let servicesToBeExecOnBatches = []
        if(deviceUuids) {
            const deviceUuidArr = deviceUuids.split(",");
            for(let iUuid in deviceUuidArr) {
                if(!servicesToBeExecOnDeviceStr) {
                    servicesToBeExecOnDeviceStr = deviceUuidArr[iUuid]
                } else {
                    servicesToBeExecOnDeviceStr += `,${deviceUuidArr[iUuid]}`
                }
                if (parseInt(iUuid) % 99 === 0 && parseInt(iUuid) > 0) {
                    servicesToBeExecOnBatches.push(servicesToBeExecOnDeviceStr)
                    servicesToBeExecOnDeviceStr =  "";
                } else if(parseInt(iUuid) + 1 === deviceUuidArr.length) {
                    servicesToBeExecOnBatches.push(servicesToBeExecOnDeviceStr)
                }
            }
        } else {
            for (let irow in rows) {

                if (!servicesToBeExecOnDeviceStr) {
                    servicesToBeExecOnDeviceStr = servicesToBeExecOnDeviceStr + ((isGroup) ? `@${rows[irow].groupid}` : rows[irow].deviceUuid)
                } else {
                    servicesToBeExecOnDeviceStr = servicesToBeExecOnDeviceStr +  ((isGroup) ? `,@${rows[irow].groupid}` : `,${rows[irow].deviceUuid}`)
                }
                if (parseInt(irow) % 99 === 0 && parseInt(irow) > 0) {
                    servicesToBeExecOnBatches.push(servicesToBeExecOnDeviceStr)
                    servicesToBeExecOnDeviceStr =  "";
                } else if(parseInt(irow) + 1 === rows.length) {
                    servicesToBeExecOnBatches.push(servicesToBeExecOnDeviceStr)
                }

    
            }
        }
        for(let batchIndex in servicesToBeExecOnBatches) {
            if(parseInt(batchIndex) > 0) {
                await setTimeoutInSeconds(2);
            }
        let serviceProgress;
        await GenericDassQuery("/rest/nodes/" + servicesToBeExecOnBatches[batchIndex] + "/services/" + service.name, {
            method: "POST",
            data: requestBody,
            headers: {
                "Accept": "text/event-stream",
            },
            chunkCallback: async (data: string, chunkIdx: number) => {
                // Your logic to handle each chunk of data
                try {
                    const jsonData = JSON.parse(data)
                    if (jsonData.totalDevices && jsonData.totalDevicesNameMatchedService && jsonData.statusCode) {
                        const totalDevices = jsonData.totalDevices;
                        const totalDevicesNameMatchedService = jsonData.totalDevicesNameMatchedService;
                        const totalDeviceNamedNotMatchedService = totalDevices - totalDevicesNameMatchedService;
                        
                        if(!serviceProgress) {
                            serviceProgress = {
                                totalDevices: totalDevices,
                                totalBatches: servicesToBeExecOnBatches.length,
                                currentBatch: parseInt(batchIndex) + 1,
                                passedCount: 0,
                                failedCount: 0,
                                warning: (totalDeviceNamedNotMatchedService/totalDevices) * 100,
                                passed: 0,
                                failed: 0,
                            };
                        }
                        if (jsonData.statusCode === 200) {
                            serviceProgress.passedCount = (serviceProgressRef.current && serviceProgressRef.current.passedCount || 0) + 1;
                            serviceProgress.passed = (serviceProgress.passedCount / totalDevices) * 100;
                        } else {
                            serviceProgress.failedCount = (serviceProgressRef.current && serviceProgressRef.current.failedCount || 0) + 1;
                            serviceProgress.failed = (serviceProgress.failedCount / totalDevices) * 100;
                        }
                        serviceProgressRef.current = serviceProgress;
                        
                        const totalInProgress = serviceProgress.passedCount + serviceProgress.failedCount + totalDeviceNamedNotMatchedService;
                        const serviceProgressCopy = JSON.parse(JSON.stringify(serviceProgress))
                        setServiceProgress(serviceProgressCopy)
                        // If all devices are processed, reset the progress
                        if (totalInProgress === totalDevices) {
                            setTimeout(() => {
                                setServiceProgress(null)
                            }, 2000)
                        }

                    }
                } catch (e) {
                    console.log(e)
                }

            }

        });
        if (serviceProgressRef.current.passedCount > 0) {
            const successMessage = isGroup 
                ? strings.BULK_GROUP_SUCCESSFUL_SERVICE_MESSAGE 
                : strings.BULK_DEVICE_SUCCESSFUL_SERVICE_MESSAGE;
        
            const batchMessage = servicesToBeExecOnBatches.length > 1 
                ? `${strings.BULK_MESSAGE_IN_BATCH} ${parseInt(batchIndex) + 1}/${servicesToBeExecOnBatches.length}` 
                : '';
            toast.success(`${successMessage} ${serviceProgressRef.current.passedCount}/${serviceProgressRef.current.totalDevices} ${batchMessage}`);
        } else {
            const errorMessage = isGroup 
                ? strings.BULK_GROUP_FAILED_SERVICE_MESSAGE 
                : strings.BULK_DEVICE_FAILED_SERVICE_MESSAGE;
        
            const batchMessage = servicesToBeExecOnBatches.length > 1 
                ? `${strings.BULK_MESSAGE_IN_BATCH} ${parseInt(batchIndex) + 1}/${servicesToBeExecOnBatches.length}` 
                : '';
        
            toast.error(`${errorMessage} ${serviceProgressRef.current.passedCount}/${serviceProgressRef.current.totalDevices} ${batchMessage}`);
        }
        serviceProgress = null
        serviceProgressRef.current = null;
        }
        
    } catch (e) {
        serviceProgressRef.current = null;
        setServiceProgress(serviceProgressRef.current)
        toast.error(e.message);
    }
}




const DmpDevices: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);

    let { deviceUuid, tabname } = useParams();
    const location = useLocation();

    const navigate = useNavigate();

    const [payloadExportShow, setPayloadExportShow] = useState(false);

    const stateInit: IMyDevicesStates = {
            loggedUser: AppContextObj.user,
            editDeviceDeveui: "",
            editDeviceIsLegacy: false,
            addDevice: false,
            pageTitle: strings.MY_APPLICATIONS_TITLE,     // should be translated
            showAlertModal: false,
            breadCrumbArr: [{label: strings.NAV_DEVICES_ITEM, url:''}],
            deviceUuid:'',
            selectedAction:'',
            showLoadingDialog:false,
            showConfirmDialog:false,
            dataTableView: 'Basic',
            confirmDialogeSettings : {
                title:'',
                description:'',
                actionLabel:'',
                confirmAction: () => {}
            },
            refresh:false,
            exportInit:0,
            isRowDeleted:false
    };

    const [deviceState, setDeviceState] = useState<IMyDevicesStates>(stateInit);

    const serviceProgressRef = useRef<ServiceProgress | null>(null);
    const [serviceProgress, setServiceProgress] = useState<ServiceProgress | null>(null);

    let DataTableContextObj = useContext(DataTableContext);


    const refreshTable = (isRowDeleted=false) => {
        setDeviceState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
    }

    function getQueryParamValue() {
        const queryParamValues = [];
        if(location && location.search) {
            const urlParams = new URLSearchParams(location.search);
            const searchConnectionUuid = urlParams.get('search_connection_uuid');
            if (searchConnectionUuid) queryParamValues.push('connection_uuid') 

            const searchDeviceProfileUuid = urlParams.get('search_device_profile_uuid');
            if (searchDeviceProfileUuid) queryParamValues.push('device_profile_uuid')
        }
        return queryParamValues
    }

    const setDataTableView = (view: "Basic" | "Detailed" | "Expanded") => {
        setDeviceState(prevState => {
            return {...prevState, dataTableView: view}
        })

        let resetStateObj = {visibleColumns: basicViewColumns } 

        if(view === 'Detailed' || isMobile || view === 'Expanded') {
            resetStateObj = {visibleColumns: advancedViewColumns } 
        }


        const queryParamValues = getQueryParamValue()
        for(let col of queryParamValues) {
            resetStateObj.visibleColumns.push(col)
        }

        DataTableContextObj.setSearchState(prev => {
            return {...prev, ...resetStateObj }
        })
        return;
    }

    const setDataTableViewActive = (view: "Basic" | "Detailed" | "Expanded") => {
        return view === deviceState.dataTableView
    }


    const exportTable = () => {
        
        setDeviceState(prevState => {
            let exportInit = (prevState.exportInit === 1)  ? 2 : 1;
            return {...prevState, exportInit:exportInit}
        })
    }


    // const showGroupSelectDialog = () => {
    //     notImplemented()
    // }

    // const showBatchEditDialog = (tf)  => {
    //     notImplemented();
    // }

    const batchDeleteDevices = async (selectedIds: IRowType[]) => {
        let importedDeviceExists = false;
        const deviceIds = selectedIds.map((row: IRowType) => {
            if(row.orbiwan_instance && row.orbiwan_instance.length > 0) {
                importedDeviceExists = true;
            }
            return (row.name || row.deviceId || row.deviceUuid);
        })
        const deviceUuidIds = selectedIds.map((row: IRowType) => {
            return row.deviceUuid
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to delete the ${deviceIds.length} selected device(s)`,
                description:  await dialogDescription(deviceIds),
                note: (importedDeviceExists ? "Note: " + strings.DELETE_DEVICE_MSG : undefined),
                actionLabel: 'Delete',
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    const deletePromises = deviceUuidIds.map(deviceUuid => 
                        GenericDassQuery(`/rest/nodes/${deviceUuid}`, { method: "DELETE" })
                    );
                    await Promise.all(deletePromises);
                    refreshTable(true);
                    toast.success(strings.BULK_DEVICE_DELETE_TOAST_SUCCESS_MESSAGE);
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }

    const deleteDevice = async (row: IRowType) => {
        let importedDeviceExists = row.orbiwan_instance && row.orbiwan_instance.length > 0;
        const confirmDialogeSettings = {
                title:  "You are about to delete a device",
                description: "Delete '" + (row.name || row.deviceId || row.deviceUuid) + "'?",
                actionLabel: 'Delete',
                note: (importedDeviceExists ? "Note: " + strings.DELETE_DEVICE_MSG : undefined),
        };

        if (await dialog(confirmDialogeSettings) === true) {
            // spinnerClass.show();
            try {
                await GenericDassQuery("/rest/nodes/" + row.deviceUuid, { method: "DELETE" });
                refreshTable(true);
                toast.success("Device successfully deleted");
            } catch (e) {
                toast.error(e.message);
            }
            // spinnerClass.hide();
        }
    }

    const suspendDevice = async (row: IRowType) => {
        const confirmDialogeSettings = {
                title:  "You are about to suspend a device",
                description: "Suspend '" + (row.name || row.deviceId || row.deviceUuid) + "'?",
                actionLabel: 'Suspend',
                faIcon: solidfaExclamationTriangle
        };

        if (await dialog(confirmDialogeSettings) === true) {

            try {
                await GenericDassQuery("/rest/nodes/" + row.deviceUuid, { method: "PUT", data: { suspended: true } });
                refreshTable();
                toast.success("Device successfully suspended");
            } catch (e) {
                toast.error(e.message);
            }
        }
    }

    const unsuspendDevice = async (row: IRowType) => {
        const confirmDialogeSettings = {
                title:  "You are about to Unsuspend a device",
                description: "Unsuspend '" + (row.name || row.deviceId || row.deviceUuid) + "'?",
                actionLabel: 'Unsuspend',
                faIcon: faExclamationTriangle
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {
                await GenericDassQuery("/rest/nodes/" + row.deviceUuid, { method: "PUT", data: { suspended: false } });
                refreshTable();
                toast.success("Device successfully Unsuspended");
            } catch (e) {
                toast.error(e.message);
            }
        }
    }


    const batchSuspendDevices = async (selectedIds: IRowType[]) => {
        const deviceIds = selectedIds.map((row: IRowType) => {
            return row.deviceUuid;
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to suspend ${deviceIds.length} the selected device(s)`,
                description: await dialogDescription(deviceIds),
                actionLabel: 'Suspend',
                faIcon: solidfaExclamationTriangle
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(deviceIds.map((deviceUuid: string) => {
                        return GenericDassQuery("/rest/nodes/" + deviceUuid, { method: "PUT", data: { suspended: true }  });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Devices successfully suspended");
                    });
                } catch (e) {
                    refreshTable();
                    toast.error(e.message);
                }
            }
        }
    }

    const batchUnsuspendDevices = async (selectedIds: IRowType[]) => {
        const deviceIds = selectedIds.map((row: IRowType) => {
            return row.deviceUuid;
        })
        if(deviceIds && deviceIds.length > 0) {
            const confirmDialogeSettings = {
                title:  `Do you want to unsuspend ${deviceIds.length} the selected device(s)`,
                description: await dialogDescription(deviceIds),
                actionLabel: 'Unsuspend',
                faIcon: faExclamationTriangle
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(deviceIds.map((deviceUuid: string) => {
                        return GenericDassQuery("/rest/nodes/" + deviceUuid, { method: "PUT", data: { suspended: false }  });
                    })).then((values) => {
                        refreshTable();
                        toast.success("Devices successfully Unsuspended");
                    });
                } catch (e) {
                    refreshTable();
                    toast.error(e.message);
                }
            }
        }
    }

    const deviceProfileFetcher = async () => {

        if(!deviceData['deviceProfiles']) {
            const deviceprofileData  = await GenericDassQuery(`/rest/device-profiles?show_region=true`, { method: "GET" });
            deviceData['deviceProfiles'] = deviceprofileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }})
        }
        return deviceData['deviceProfiles'];
    }

    const connectionFetcher = async () => {

        if(!deviceData['connectors']) {
            const connectionData  = await GenericDassQuery(`/rest/connections?show_region=true`, { method: "GET" });
            deviceData['connectors'] = connectionData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }});
        }
        return deviceData['connectors'];

    }

   

    const serviceVisible = (row: IRowType, entry: number) => {
        if(row?.suspended) {
            return false;
        }
        const services = (row?.services || []).filter(s => (s.show_on_ui && AppContextObj.user?.account_status === "active" && ((AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)) && !AppContextObj.user?._readonly));
        return !!services[entry];
    }

    const serviceIcon = (row: IRowType, title: string, entry: number) => {
        const services = (row?.services || []).filter(s => (s.show_on_ui && AppContextObj.user?.account_status === "active" && !AppContextObj.user?._readonly));
        const service = services[entry];
        if(service) {
            let formatButtonText = service.buttonText || service.name
            if(title) {
                if(typeof(trustedFormatText(`${service.buttonText || service.name}`, row.deviceUuid, { log: console.log }, null)[0]) == 'object') {
                    formatButtonText = `${service.buttonText || service.name} ${title}`
                } else {
                    formatButtonText = `[[fa-circle-small]] ${title}`
                }
            } else {
                if(typeof(trustedFormatText(`${service.buttonText || service.name}`, row.deviceUuid, { log: console.log }, null)[0]) == 'string') {
                    formatButtonText = elipsis(`${service.buttonText || service.name}`, 8)
                }
            }
            return trustedFormatText(formatButtonText, row.deviceUuid, { log: console.log }, null);
        } else {
            return null;
        }
        
    };

    const getActions = () => {


        const actions: ActionType<IRowType>[] = [

        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_SEND,
            render: (row, title) => actionIcon(row.deviceUuid, title, faPaperPlane.iconName),
            action: (row) => detailPageNav(navigate, TAB_SEND_TO_DEVICE, row.deviceUuid, row),
            visible: (device) => {
                return device?.device_status === 3 && AppContextObj.user?.account_status === "active" && !AppContextObj.user?._readonly
            }
        }, 
        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_DATA,
            render: (row, title) => actionIcon(row.deviceUuid, title ,faDatabase.iconName),
            action: (row) => detailPageNav(navigate, TAB_VIEW_DATA, row.deviceUuid, row),
            visible: () => (AppContextObj.user?.account_status === "active")
        },
        {
            type: "action",
            text: strings.SERVICE_SCHEDULER,
            render: (row, title) => actionIcon(row.deviceUuid, title, faClock.iconName),
            visible: (row) => (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices) && !AppContextObj.user?._readonly && row.services.length > 0,
            action: (row) => execScheduleService([row], -1)
        },
        {
            type: "action",
            text: strings.MY_DEVICES_ACTION_DELETE,
            render: (row, title) => actionIcon(row.deviceUuid, title, faTrashCan.iconName),
            visible: () => (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices) && !AppContextObj.user?._readonly,
            action: (row) => deleteDevice(row)
        },
        {
            type: "action",
            text: strings.MY_DEVICES_DETAIL,
            render: (row, title) => actionIcon(row.deviceUuid, title, faList.iconName),
            action: (row) => detailPageNav(navigate, TAB_DEVICE_DETAIL, row.deviceUuid, row),
            visible: () => false
        },
        {
            type: "action",
            text: strings.MY_DEVICE_SUSPEND,
            render: (row, title) => actionIcon(row.deviceUuid, title, solidfaExclamationTriangle.iconName),
            action: (row) => suspendDevice(row),
            visible: (row) => {
                return row?.suspended === false 
                       && AppContextObj.user?.account_status === "active" 
                       && !AppContextObj.user?._readonly
                       && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)

            } 
        },
        {
            type: "action",
            text: strings.MY_DEVICE_UNSUSPEND,
            render: (row, title) => actionIcon(row.deviceUuid, title, faExclamationTriangle.iconName),
            action: (row) => unsuspendDevice(row),
            visible: (row) => {
                return row?.suspended === true 
                       && AppContextObj.user?.account_status === "active" 
                       && !AppContextObj.user?._readonly
                       && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)
            } 
        },
        {
            type: "action",
            text: strings.MY_DEVICES_DATA,
            render: (row, title) => actionIcon(row.deviceUuid, title, faList.iconName),
            action: (row) => detailPageNav(navigate, TAB_VIEW_DATA, row.deviceUuid, row),
            visible: () => false //AppContextObj.user?.account_status === "active" 
        }];

        const bulkActions: BulkActionType<IRowType>[] = [
            {
                type: "header",
                text: "Default Actions"
            },
            // {
            //     type: "action",
            //     text: strings.MY_DEVICES_ACTION_EDIT_DEVICES,
            //     render: (row, title) => actionIcon(row.deviceUuid, title, faCreditCard.iconName),
            //     action: async(selectedIds) => deviceEditBulkAction(navigate, TAB_EDIT_BULK_DEVICE, selectedIds),
            //     visible: () => {
            //         return AppContextObj.user?.account_status === "active" 
            //                && !AppContextObj.user?._readonly
            //                && AppContextObj.user?.can_register
            //     } 
            // }, 
            {
                type: "action",
                text: strings.MY_DEVICES_ACTION_DELETE_DEVICES,
                render: (row, title) => actionIcon(row.deviceUuid, title, faTrashCan.iconName),
                action: (selectedIds) => batchDeleteDevices(selectedIds),
                visible: () => { 
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           &&  AppContextObj.user?.can_register
                } 
            },
            {
                type: "action",
                text: strings.SERVICE_SCHEDULER,
                render: (row, title) => actionIcon(row.deviceUuid, title, faClock.iconName),
                action: (selectedIds) => execScheduleService(selectedIds, -1),
                bulkActionVisible: (selectedIds) => { 
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices) 
                           && visibleScheduleService(selectedIds)
                } 
            },
            {
                type: "action",
                text: strings.SUSPEND_DEVICES,
                render: (row, title) => actionIcon(row.deviceUuid, title, solidfaExclamationTriangle.iconName),
                action: (selectedIds) => batchSuspendDevices(selectedIds),
                visible: () => {
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.UNSUSPEND_DEVICES,
                render: (row, title) => actionIcon(row.deviceUuid, title, faExclamationTriangle.iconName),
                action: (selectedIds) => batchUnsuspendDevices(selectedIds),
                visible: () => {
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)
                } 
            },
            {
                type: "action",
                text: strings.GROUP_ASSIGNMENT,
                render: (row, title) => actionIcon(row.deviceUuid, title, faEdit.iconName),
                action: async(selectedIds) => deviceBulkAction(navigate, TAB_GROUP_ASSIGNMENT, selectedIds),
                visible: () => {
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)
                } 
            }, {
                type: "action",
                text: strings.APP_ASSIGNMENT,
                render: (row, title) => actionIcon(row.deviceUuid, title, faEdit.iconName),
                action: async(selectedIds) => deviceBulkAction(navigate, TAB_APP_ASSIGNMENT, selectedIds),
                visible: () => {
                    return AppContextObj.user?.account_status === "active" 
                           && !AppContextObj.user?._readonly
                           && (AppContextObj.user?.can_register || AppContextObj.user?.can_control_devices)
                } 
            }];


        return {
            actions: actions,
            bulkActions:bulkActions
        }
    };
    const dmpDeviceImportPageNav = ( navigate) => {
        
        navigate(`${getAppBase()}/device-import`)
    }

    const addServiceActions = ( row: IRowType) => {
        let serviceActions = [];

        for(let index in row.services) {
            
            let serviceAction = {
                type: "action",
                text: row.services[index].title || row.services[index].name,
                render: (row, title) => serviceIcon(row, title, parseInt(index)),
                action: (row) => execService([row], parseInt(index), false),
                visible: (row) => serviceVisible(row, parseInt(index))
            }
            serviceActions.push(serviceAction);    
        }
        return [...serviceActions, ...getActions().actions];
    }

    const addBulkServicesActions = (row: IRowType, bulkActions: BulkActionType<IRowType>[]) => {
        let serviceActions = [];
            for (let index in row.services) {
                let serviceName = row.services[index].name;
                if(bulkActions == null) bulkActions = [];
                // Check if the serviceName is not present in bulkActions
                if (!bulkActions?.find(action => (action.text === serviceName))) {
                    let serviceAction = {
                        type: "action",
                        text: serviceName,
                        render: (row, title, index) => serviceIcon(row, title, parseInt(index)),
                        action: (rows, index) => execService(rows, parseInt(index), true, false, setServiceProgress, serviceProgressRef, serviceName),
                        visible: (row, index) => serviceVisible(row, parseInt(index)),
                        hasServiceParameters: Boolean(row.services[index].has_parameters)
                    };
                    serviceActions.push(serviceAction);
                }
            }
        return [...serviceActions, ...bulkActions];
    };


    const getPageButtons = useCallback(() => {
    
        let pageButtons: PageButtonType[] = [];

        if(isMobile) {
            pageButtons = [
                {
                    title: strings.DEVICE_BTN,
                    action: () => detailPageAdd(navigate, strings.ADD_DEVICE_BTN),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.can_register && !AppContextObj.user._readonly,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() },
                    type: 'button',
                    icon: faRefresh
                },
                {
                    title: strings.EXPORT_DEVICES_BTN_LABEL,
                    icon: faFileExport,
                    type: 'button_with_icon',
                    action: () => { 
                        exportTable() 
                    },
                },
                {
                    title: strings.EXPORT_PAYLOADS_BTN_LABEL,
                    icon: faFileExport,
                    type: 'button_with_icon',
                    action: () => toggleExportPayloadsModal(),
                },
                {
                    title: strings.IMPORT_DEVICES_BTN_LABEL,
                    action: () => dmpDeviceImportPageNav(navigate),
                    icon: faFileImport,
                    type: 'button_with_icon',
                    visible: () => AppContextObj.user?.can_register
                    && !AppContextObj.user?._readonly,
                },
                {
                    title: strings.PROVISION_DEVICES_BTN,
                    action: () => provisionPageAdd(navigate, strings.PROVISION_DEVICES_BTN),
                    icon: faList,
                    type: 'button_with_icon',
                    visible: () => AppContextObj.user?.can_register && !AppContextObj.user._readonly,
                },

            ]
        } else {
            pageButtons = [
                {
                    title: strings.DEVICE_DATATABLE_VIEW,
                    actionView: (view: "Basic" | "Detailed" | "Expanded") => setDataTableView(view),
                    type: 'button_with_group',
                    iconGroup: [faGridRound, faGridRound4, faGridRound5],
                    dataTableView: (view: "Basic" | "Detailed" | "Expanded") => setDataTableViewActive(view)
                },
                {
                    title: strings.DEVICE_BTN,
                    action: () => detailPageAdd(navigate, strings.ADD_DEVICE_BTN),
                    type: 'button_with_icon',
                    icon: faAdd,
                    visible: () => AppContextObj.user?.can_register && !AppContextObj.user._readonly,
                },
                {
                    title: strings.REFRESH_LIST,
                    action: () => { refreshTable() },
                    type: 'button',
                    icon: faRefresh
                },
                {
                    title: strings.EXPORT_DEVICE_BTN,
                    type: 'dropdown_only_icon',
                    icon: faEllipsis,
                    className:'ow-datatable-more-action-dropdown',
                    dropDownItems: [
                        {
                            title: strings.EXPORT_DEVICES_BTN_LABEL,
                            icon: faFileExport,
                            action: () => { 
                                exportTable() 
                            },
                        },
                        {
                            title: strings.EXPORT_PAYLOADS_BTN_LABEL,
                            icon: faFileExport,
                            action: () => toggleExportPayloadsModal(),
                        },
                        {
                            title: strings.IMPORT_DEVICES_BTN_LABEL,
                            action: () => dmpDeviceImportPageNav(navigate),
                            icon: faFileImport,
                            style: {marginRight: '11px'},
                            visible: AppContextObj.user?.can_register
                                           && !AppContextObj.user?._readonly,
                        },
                        {
                            title: strings.PROVISION_DEVICES_BTN,
                            action: () => provisionPageAdd(navigate, strings.PROVISION_DEVICES_BTN),
                            icon: faList,
                            style: {marginRight: '11px'},
                            visible: AppContextObj.user?.can_register && !AppContextObj.user._readonly,
                        },
                        
                    ]
                }, 
                {
                    title: strings.COLUMNS,
                    action: () => { console.log(strings.ADD_USER) },
                    type: 'column',
                    icon: faListCheck
                }
            ];
        }
       


        return pageButtons;
    },[]);

    const optionFetcher = async () => {
        try {
            if(!deviceData['groups']) {
                let pageUrl = `/rest/groups`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const groups =  response.data;
                let groupOptions = _.map(groups,(group) => {
                    return {label: group.groupid,value: group.groupid}
                })
                deviceData['groups'] = groupOptions;
            }
            return deviceData['groups'];
            

        }catch(e) {
            console.log(e);
        }

    }

    const applicationFetcher = async () => {
        try {
            
            if(!deviceData['applications']) {
                let pageUrl = `/rest/applications`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const applications =  response.data;
                let options = _.map(applications, (app) => {
                    if(app.accountid) {
                        return { label : app.accountid,  value: app.accountid }
                    }
                    return null;
                })
                deviceData['applications'] = options;
            }
            return deviceData['applications']
            
        }catch(e) {
            console.log(e);
        }
    }

    const tagsFetcher = async () => {
        try {

            if(!deviceData['tags']) {
                let pageUrl = `/rest/tags`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const tags =  response.data;
                let tagOptions = tags.map((tag) => {
                    return {label: tag.tagid, value: tag.tagid}
                })
                deviceData['tags'] = tagOptions;
            }
            return deviceData['tags'];
            


        } catch(e) {
            console.log(e);
        }
    }

    useEffect(() => {

        emptyDeviceData();
        
        if(checkLogin(AppContextObj.user)) {
            setDeviceState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }
        deviceProfileFetcher()
        connectionFetcher()
        optionFetcher()
        applicationFetcher()
        tagsFetcher()
        
        
    },[AppContextObj.user?.userid, deviceState.refresh])
    
    const deviceBulkAction = ( navigate, tab, selectedIds: IRowType[] ) => {

        const idArr = selectedIds.map((row) => {
            return row.deviceUuid;
        })
        navigate(`${getAppBase()}/dmp-devices/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr, prevPageUrl: `${getAppBase()}/dmp-devices`}})

    }
    // const deviceEditBulkAction = ( navigate, tab, selectedIds: IRowType[] ) => {

    //     if (selectedIds.length > 0) {
    //         const idArr = selectedIds.map((row) => { 
    //             return row.deviceUuid
    //         });
    //         navigate(`${getAppBase()}/dmp-devices/bulkaction/${tab}`, {state: {tab: tab, selectedIds:idArr , prevPageUrl: `${getAppBase()}/dmp-devices`}})
    //     } else { 
    //         toast.error("No Device Selected!");
    //     }

    // }


    const detailPageNav = ( navigate, tab, deveui, row) => {

        navigate(`${getAppBase()}/dmp-devices/${deveui}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/dmp-devices`}})
    }

    const toggleExportPayloadsModal = (  ) => {
        setPayloadExportShow(prevState => !payloadExportShow)
    }


    const detailPageAdd = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/dmp-devices/${TAB_ADD_DEVICE}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/dmp-devices`}})
    }

    const provisionPageAdd = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/dmp-devices/${TAB_PROVISION_DEVICES}`, {state: {tab: tab, prevPageUrl: `${getAppBase()}/dmp-devices`}})
    }


    const renderDeviceProfile = (id) => {
        let display = "";
        try{
            if(deviceData['deviceProfiles'] && deviceData['deviceProfiles'].length > 0){
                const findIndex = deviceData['deviceProfiles'].findIndex((res) => res.value == id);
                if(findIndex != -1){
                    display = deviceData['deviceProfiles'][findIndex].label
                } 
            }
        } catch(e){
            console.log("e",e)
        }

        
        return display
        
    }

    const renderConnection = (id) => {
        let display = "";
        try{
            if(deviceData['connectors'] && deviceData['connectors'].length > 0) {
                const findIndex = deviceData['connectors'].findIndex((res) => res.value == id);
                if(findIndex != -1){
                    display = deviceData['connectors'][findIndex].label
                } 
            }
        } catch(e){
            console.log("e",e)
        } 
        return display
    }

    const getDeviceNameOrId = (row: IRowType) => {
        let devicesInfo = [];
        if(row.name) devicesInfo.push(row.name)
        if(row.deviceId) devicesInfo.push(row.deviceId)
        devicesInfo.push(row.deviceUuid)
        return devicesInfo
    }

    const renderConnectionHealthStatusTooltip = (row: IRowType) => {
        if(isMobile) return(<></>);
        let lastConnectedDate, lastConnectedTime, lastDisconnectedDate, lastDisconnectedTime;
        if (row.last_connected_tmst && !isNaN(Date.parse(row.last_connected_tmst))) {
            lastConnectedDate = new Date(row.last_connected_tmst).toLocaleDateString();
            lastConnectedTime = new Date(row.last_connected_tmst).toLocaleTimeString();
        }
        if (row.last_disconnected_tmst && !isNaN(Date.parse(row.last_disconnected_tmst))) {
            lastDisconnectedDate = new Date(row.last_disconnected_tmst).toLocaleDateString();
            lastDisconnectedTime = new Date(row.last_disconnected_tmst).toLocaleTimeString();
        }
        return (
            <>
                <Popover.Header as="h3" className="mt-0"></Popover.Header>
                <Popover.Body className="p-2">
                <span title={(row.connectionStatus === "disconnected" ? strings.DISCONNECTED_TOOLTIP : ((row.connectionStatusErrorMessage) ? row.connectionStatusErrorMessage : ''))} className="ms-auto" style={{ float: 'right' }}>{getConnectionStatusIcon(row)}</span>
                    <Stack direction="horizontal" gap={3}><span className="fw-bold">{getDeviceNameOrId(row)[0]}</span></Stack>
                    <small className="mb-2">{row.remote_ip_address || getDeviceNameOrId(row)[1] || ""}</small>
                    <div className="ow-popover-status mb-2"><span title={`Device Health`}>{getLedIcon(row)}</span><span className={`${getLedIcon(row, true)}`}>{` ${row.operationalStatusMessage || renderHealthTooltip(row)}`}</span></div>
                   

                    {(row.last_connected_tmst || row.last_disconnected_tmst) && (<> <hr className="my-1"/>
                        <div><Stack direction="horizontal" gap={3}>
                            {lastConnectedDate && (<div><Stack className="align-items-start" direction="horizontal" gap={3}>
                                <span> <Image className="mt-1" src={last_connected} width={30} height={30} /></span>
                                <span style={{fontSize: 12}}>{lastConnectedDate}
                                    <p className="mb-0">{lastConnectedTime}</p></span>
                            </Stack>
                            </div>)}

                            {lastDisconnectedDate && (<div><Stack  className="align-items-start" direction="horizontal" gap={3}>
                                <span> <Image className="mt-1" src={last_disconnected} width={30} height={30}/></span>
                                <span style={{fontSize: 12}}>{lastDisconnectedDate}
                                    <p className="mb-0">{lastDisconnectedTime}</p></span>
                            </Stack>
                            </div>)}
                        </Stack>
                        </div> </>)}
                </Popover.Body>
            </>
        );
    }

    

    const renderStatus = (row: IRowType) => {
        if (row.stateSchema && row.stateSchema.properties) {
          const statusKeys = Object.keys(row.stateSchema.properties);
      
          const visibleStatusKeys = statusKeys.filter((key) => {
            const prop = row.stateSchema.properties[key];
            return (
              prop &&
              prop.type !== "array" &&
              prop.type !== "object" &&
              row.state &&
              row.state[key] !== null &&
              row.state[key] !== undefined &&
              (!prop.$uiSchemaObject || (prop.$uiSchemaObject && prop.$uiSchemaObject.hidden !== true))
            );
          });
      
          const renderStatusCol = (key) => {
            const value = row.state[key];
            const typeOfFirstStatus = typeof value;
            let textColor;
            switch (typeOfFirstStatus) {
              case 'boolean':
                textColor = '#0033b3'; //dark blue for boolean
                break;
              case 'string':
                textColor = '#068b06'; //green for string
                break;
              case 'number':
                textColor = '#285bda'; //light blue for number
                break;
              default:
                textColor = '#f78c6c'; //brown  for null and default
            }
      
            return (
              <ListGroup.Item as="li" key={key} className="d-flex">
                <div className="w-100">
                  <div className="text-wrap text-break my-1">
                    <div className="fa-80 ow-datatable-overflow-ellipsis" title={row.stateSchema.properties[key].title}>{trustedFormatText(row.stateSchema.properties[key].title || key, key, null, null)}
                    <div style={{ color: textColor }} className={`fa-85 ow-datatable-overflow-ellipsis`} title={value.toString()}>{value.toString()}</div></div>
                  </div>
                </div>
              </ListGroup.Item>
            );
          };
      
          // FIXME: don't use schema-engine classes here
          if (visibleStatusKeys.length > 0) {
            const popover = (
              <>
                <Popover.Header as="h3" className="mt-0">{strings.STATE}</Popover.Header>
                <Popover.Body className="p-2">
                  <ListGroup variant="flush schema-engine-status-list" as="ul">
                    {visibleStatusKeys.map((key) => renderStatusCol(key))}
                  </ListGroup>
                </Popover.Body>
              </>
            );

            
      
            const StatusOverlay = () => {
                const handleMoreButtonClick = (ev) => {
                    ev.stopPropagation();
                  };
                  return (
                    <HoverOverlay big={true} overlay={popover} className="ow-popover">
                      <small className='ms-2'><i className="fa-regular fa-message-dots" onClick={handleMoreButtonClick}></i></small>
                    </HoverOverlay>
                  );
            }
      
            if (visibleStatusKeys.length > 1 && deviceState.dataTableView !== 'Expanded') {
              return (
                <>
                  <ListGroup variant="flush schema-engine-status-list" as="ul" className="ow-datatable-overflow-ellipsis">
                    {renderStatusCol(visibleStatusKeys[0])}
                  </ListGroup>
                  {StatusOverlay()}
                </>
              );
            } else {
              return (
                <>
                  <ListGroup variant="flush schema-engine-status-list" as="ul" className="ow-datatable-overflow-ellipsis">
                    {visibleStatusKeys.map((key) => renderStatusCol(key))}
                  </ListGroup>
                </>
              );
            }
          }
        }
      
        return null;
      };

    const  initDataTable = () => {


       


        
        const { actions, bulkActions } = getActions();
        const appBase = getAppBase();

        const navigate = useNavigate();

        type FilterOptionType = {
            label: string;
            value: string;
        }

        // "normal" | "warning" | "error" | "critical"

        function filterOperationalStatus():FilterOptionType[] {
            return JSON.parse(JSON.stringify(OPERATIONAL_STATUS_OPTIONS));
        }

        function filterConnectionStatus():FilterOptionType[] {
            return JSON.parse(JSON.stringify(CONNECTION_STATUS_OPTIONS));
        }
        
        //`${appBase}/groups/`
       
        const ColWidth = {
            "bulk_action_checkbox": "30px",
            "deviceUuid": "90px",
            "name": "350px",
            "image_url": "80px",
            "image": "70px",
            "operationalStatus": "140px",
            "state": "140px",
            "description": "350px",
            "groups": "130px",
            "global_tags": "130px",
            "applications": "130px",
            "last_update_tmst": "160px",
            "device_profile_uuid": "150px",
            "connection_uuid": "150px",
            "connectionStatus": "140px"

        }

        

        let columns: ColumnType<IRowType>[] = [

            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: ColWidth.bulk_action_checkbox,
                customClass: 'sticky left-first',
            },
            {
                key: 'deviceUuid',
                type: "text",
                title: strings.TABLE_UUID,
                render: (row) => (isMobile ? row.deviceUuid : trustedFormatText(`[[fa-ellipsis]]${row.deviceUuid.slice(-6)}`, row.deviceUuid, null, null)),
                cellWidth: 3,
                newCellWidth: (isMobile ? UUIDCOLWIDTH : ColWidth.deviceUuid),
                copyLink: true,
                customClass: 'font-monospace fa-80 sticky left-second',
                render_tooltip: (row) => row.deviceUuid,
                filterable: true,
                filterField: "search_deviceuuid",
                sortable: true,
                sortKey: "sort_by_deviceuuid",
                filterType: "text",
                dataAlign: "center",
                enabledInColumn: false,
                inputValidation: ID_INPUT_VALIDATION

            },
            {
                key: "name",
                title: "Name",
                type: "text",
                filterable: true,
                filterField: "search_name",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                detailLink: true,
                enabledInColumn: true,
                doNotShowInColumnDropDown: true,
                detailPageNav: (row) =>  detailPageNav(navigate, "device-detail", row.deviceUuid, row ),
                sortKey: "sort_by_name",
                filterParams: {},
                cellWidth: 50,
                newCellWidth: isMobile ? '180px' : ColWidth.name,
                dataAlign:'left',
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.name
            }, 
            {
                key: "image_url",
                title: "Image",
                type: "icon_with_tooltip",
                filterable: false,
                enabledInColumn: true,
                render: (row) => <img style={{maxHeight: "30px", maxWidth:row.image_url ? ColWidth.image : '', height: "auto", width: "auto" } } src={row.image_url?.startsWith("storageid:") 
                ? "/storagecache/" + row.image_url.substring(10)
                : row.image_url} />,
                cellWidth: 20,
                dataAlign:'center',
                newCellWidth: isMobile ? '50px' : ColWidth.image_url
            },
            {
                key: "operationalStatus",
                title: strings.OPERATIONALHEALTH,
                type: "text",
                render: (row) => getLedIcon(row),
                render_overlay_tooltip: (row) => renderConnectionHealthStatusTooltip(row),
                render_overlay_classNames: "ow-popover ow-popover-connection-status",
                filterable: true,
                enabledInColumn: true,                
                filterParams: {
                    mapper: (x) => x,
                    optionFetcher: filterOperationalStatus,
                    data: []
                },
                filterField: 'search_operational_status',
                filterType: 'multiselect',
                dataAlign:'center',
                cellWidth: 6,
                newCellWidth: isMobile ? '70px' : "140px"
            },
            {
                key: "connectionStatus",
                title: strings.CONNECTIONSTATUS,
                type: "text",
                render: (row) => getConnectionStatusIcon(row, true),
                render_overlay_tooltip: (row) => renderConnectionHealthStatusTooltip(row),
                render_overlay_classNames: "ow-popover ow-popover-connection-status",
                filterable: true,
                sortable: true,
                enabledInColumn: false,
                sortKey: "sort_by_connection_status",
                filterParams: {
                    mapper: (x) => x,
                    optionFetcher: filterConnectionStatus,
                    data: []
                },
                filterField: 'search_connection_status',
                filterType: 'multiselect',
                dataAlign:'center',
                cellWidth: 6,
                newCellWidth: ColWidth.connectionStatus
            },
            {
                key: "operationalStatusMessage",
                title: strings.OPSTATUSPMESSGAE,
                type: "text",
                filterable: false,
                enabledInColumn:false,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                cellWidth: 50,
                newCellWidth: ColWidth.device_profile_uuid,
                extraClass: 'ow-datatable-overflow-ellipsis',
                dataAlign:'left',
            }, 
            {
                key: "state",
                title: strings.STATE,
                type: "text",
                render: (row) => renderStatus(row),
                filterable: false,
                detailLink: false,
                enabledInColumn: true,
                customNavigation: (row) => {
                    detailPageNav(navigate, "device-detail", row.deviceUuid, row)
                },
                filterField: "",
                filterType: "",
                filterParams: {
                    mapper: x => x + "!123"
                },
                cellWidth: 6,
                extraClass: 'd-flex align-items-center',
                newCellWidth: ColWidth.state
            },
            {
                key: "description",
                title: strings.DESCRIPTION,
                type: "text",
                filterable: true,
                filterField: "search_description",
                filterType: "text",
                enabledInColumn: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_description",
                filterParams: {},
                cellWidth: 50,
                dataAlign:'left',
                doNotShowInColumnDropDown: true,
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.description
            }, 
            {
				key: "device_profile_uuid",
				title: strings.DEVICE_PROFILE,
				type: "text",
				enabledInColumn:true,
				newCellWidth: ColWidth.device_profile_uuid,
                render: (row:IRowType) => renderDeviceProfile(row.device_profile_uuid),
                render_tooltip: (row:IRowType) => renderDeviceProfile(row.device_profile_uuid),
                filterField: 'search_device_profile_uuid',
                filterable: true,
                filterType: "multiselect",
                extraClass: 'ow-datatable-overflow-ellipsis',
                filterParams: {
                    optionFetcher: deviceProfileFetcher,
                    data: [],
                    mapper: x => x,
                },
			},
            {
				key: "connection_uuid",
				title: strings.CONNECTION,
				type: "text",
				enabledInColumn:false,
				newCellWidth: ColWidth.connection_uuid,
                filterable: true,
                inputValidation: DEFAULT_INPUT_VALIDATION,
                render: (row) => renderConnection(row.connection_uuid),
                render_tooltip: (row) => renderConnection(row.connection_uuid),
                filterField: 'search_connection_uuid',
                filterType: "multiselect",
                extraClass: 'ow-datatable-overflow-ellipsis',
                filterParams: {
                    optionFetcher: connectionFetcher,
                    data: [],
                    mapper: x => x,
                },
			},  
            {
                key: "groups",
                title: strings.MY_DEVICES_GROUPS,
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                enabledInColumn: false,
                // render_tooltip: x => (x.groups || "").replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.groups, 'gray', `${appBase}/groups/~tag~/group-info`, {prevPageUrl:`${getAppBase()}/dmp-devices`,row: { groupid:0 },}, "Groups", '95px') },
                // customNavigation: () => {}, //blank customNavigation will stop whole row clickable behavior so tag clicking can work
                customNavigation: (x) => {
                    if(x.groups){return null} else {
                    detailPageNav(navigate, "device-detail", x.deviceUuid, x )
                }},
                filterable: true,
                filterField: "group",
                filterType: "multiselect",        
                extraClass: 'd-flex align-items-center',        
                newCellWidth: ColWidth.groups,
                filterParams: {
                    optionFetcher: optionFetcher,
                    data: [],
                    mapper: x => x,
                    },
                // extraClass: 'ellipsis-class',
            },
            {
                key: "global_tags",
                title: "Tags", // FIXME Add string.xx
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                enabledInColumn: false,
                render: x => { return RenderTagsOverlay(x.global_tags, "gray", `${appBase}/tags/~tag~/edit-tag`, {prevPageUrl:`${getAppBase()}/dmp-devices`,row: { tagid:0 },}, "Tags",'95px') },
                customNavigation: (x) => {
                    if(x.global_tags){return null} else {
                    detailPageNav(navigate, "device-detail", x.deviceUuid, x )
                }},
                filterable: true,
                filterField: "global_tags",
                filterType: "multiselect",    
                extraClass: 'd-flex align-items-center',            
                newCellWidth: ColWidth.global_tags,
                filterParams: {
                    optionFetcher: tagsFetcher,
                    data: [],
                    mapper: x => x,
                }
            }
        ];

        if (!AppContextObj.user?.app_uuid) {
            // For application accounts we don't show the applications column
            columns.push({
                key: "applications",
                title: strings.MY_DEVICES_APPLICATIONS,
                type: "text_with_tooltip",
                // render_tooltip: x => (x.applications || "").replace(/,/g, ', '),
                render: x => { return RenderTagsOverlay(x.applications, 'gray', `${appBase}/my-apps/~tag~/edit-app`,{prevPageUrl:`${getAppBase()}/dmp-devices`,row: { accountid:0 }}, "Applications", '95px')},
                // customNavigation: () => {}, //blank customNavigation will stop whole row clickable behavior so tag clicking can work
                customNavigation: (x) => {
                    if(x.applications){return null} else {
                    detailPageNav(navigate, "device-detail", x.deviceUuid, x )
                }},
                filterable: true,
                filterField: "application",
                filterType: "multiselect",
                enabledInColumn: false,
                cellWidth: 35,
                cellWidthType: '%',
                dataAlign: "left",
                newCellWidth: ColWidth.applications,
                extraClass: 'd-flex align-items-center',
                filterParams: {
                    optionFetcher: applicationFetcher,
                    data: apps,
                    mapper: (x) => x,
                },
                // extraClass: 'ellipsis-class',
            });
        }

        columns.push({
            key: "last_update_tmst",
            title: strings.LAST_SEEN,
            type: "text",
            filterable: true,
            filterField: "date",
            filterType: 'daterange',
            enabledInColumn: true,
            filterParams: {
                startField: "from_date",
                endField: "to_date",
                mapper: (x) => x && x.format()
            },
            sortable: true,
            sortKey: "sort_by_date",
            newCellWidth: ColWidth.last_update_tmst,
            render: x => dateTimeString(x.last_update_tmst),
            cellWidth: 34,
            customClass: 'font-monospace fa-75',

        });

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });
        
        if (deviceState.dataTableView === 'Detailed' || isMobile || deviceState.dataTableView === 'Expanded') {
            columns.map((column) => {
                 column.enabledInColumn = advancedViewColumns.includes(column.key);
                if(deviceState.dataTableView === 'Expanded' && column.extraClass && column.extraClass.includes('ow-datatable-overflow-ellipsis')) {
                    column.extraClass = column.extraClass.replace('ow-datatable-overflow-ellipsis', 'ow-datatable-remove-ellipsis');
                }
                if(deviceState.dataTableView === 'Detailed' && column.key === 'deviceUuid') {
                    column.newCellWidth = '320px';
                    column.render = (row) => row.deviceUuid;
                }
            })
            if(isMobile) prioritizedTableColumns(['bulk_action_checkbox', 'name', 'operationalStatus'], columns)
        } else {
            columns.map((column) => {
                column.enabledInColumn = basicViewColumns.includes(column.key);
            })
        }
        
        const queryParamValues = getQueryParamValue()
        if(queryParamValues.length > 0) {
            columns.map((column) => {
                if(queryParamValues.includes(column.key)) {
                    column.enabledInColumn = true;
                }
            })
        }
        const options: DataTableOption<IRowType> = {
            url: '/uiapi/rest/nodes',
            query_param: {all:true, get_pages:true, limit: DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number: false,
            id_field: 'deviceUuid',
            oboe_path: 'pages.*',
            available_key: 'deviceUuid',
            allowBulkActions: true,
            defaultSortField: 'sort_by_name',
            defaultSortOrder: 'asc',
            exportPath: 'uiapi/rest/export/devices?download_filename=devices.csv',
            emptyDataMsg: strings.NO_DEVICE_AVAILABLE,

            emptyDataButton: AppContextObj.user?.can_register ? {
                title: strings.DEVICE_BTN,
                action: () => detailPageAdd(navigate, strings.ADD_DEVICE_BTN),
                type: 'button_with_icon',
                icon: faAdd
            } : undefined,

            modal: false,
            columns,
            actions: actions,
            addServiceActions: addServiceActions,
            addBulkServicesActions: addBulkServicesActions,
            resizeWidth:['name', 'description'],
            bulkActions: getVisibleActions(bulkActions)
            // ...actions
        }

        return options;
    }


    let refresh = deviceState.refresh;
    const dataTableOptions = initDataTable()

    return (
                <PageContent
                    name="dmp-device"
                    id={deviceUuid}
                    tabname={tabname}
                    breadCrumbArr={deviceState.breadCrumbArr}
                    pageButtons={getPageButtons()}
                    countLabel="Devices"
                    dataTableOption={dataTableOptions}
                    serviceProgress={serviceProgress}
                    exportInit={deviceState.exportInit}
                    isRowDeleted={deviceState.isRowDeleted}
                    refresh={refresh}
                    dataTableView={deviceState.dataTableView}
                >
                    <PayloadExportModal handleClose={toggleExportPayloadsModal} show={payloadExportShow} DataTableContextObj={DataTableContextObj} />
                </PageContent>
            )

}

export default DmpDevices;