import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { PageHeader } from "../../components/Common";
import{ getAppBase }  from "../../utils/consts"
import MyFirmwares from "./FirmWareComponent";
import SelectDevices from "./DevicesComponents";
import DetailPage from "./FuotaDetail";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle as solidfaExclamationTriangle }  from '@fortawesome/pro-solid-svg-icons';
import { strings } from "../../services/Localization";
import { DataTableContext } from "../../components/Common/DataTable/DataTableState";

const CampaignStepper: React.FC<{}> = () => {


    const [activeStep, setActiveStep] = useState(0);
    const firmwareRef:any = useRef();
    const devicesRef:any = useRef();
    const navigate = useNavigate();
    const breadCrumbArr =  [
        {
            label: strings.NAV_DEVICES_ITEM,
            url:`${getAppBase()}/my-devices`
        },
        {
            label: strings.NAV_FUOTA_CAMPAIGNS_ITEM,
            url:`${getAppBase()}/my-campaigns`
        },
        {
            label:"Create Campaign",
            url: ""
        }
    ];

    const detailPageAdd = () => {
        navigate(`${getAppBase()}/my-campaigns`, {state: {}})
    }

    const goToPrevious = () => {
        if(activeStep != 0){
            setActiveStep(activeStep - 1);
        }
    }

    const goToNext = () => {
        if(activeStep == 0) {
            if(firmwareRef?.current?.goToDevices()){
                setActiveStep(activeStep+1);
            }
        } else if (activeStep == 1) {
            if(devicesRef?.current?.goToDetails()){
                setActiveStep(activeStep+1);
            }
        }
    }

    let DataTableContextObj = useContext(DataTableContext);

    const pageButtons = [
        {
            title: "Cancel",
            action: () => detailPageAdd(),
            type: 'button_with_text',
            icon: null,
            varient:"primary"
        },
        {
            title: "Previous",
            action: () => goToPrevious(),
            type: 'button_with_icon',
            icon: null,
            varient:"primary"
        },
        {
            title: "Next",
            action: () => goToNext(),
            type: 'button_with_icon',
            className: DataTableContextObj?.searchState?.name === 'fuota-campaign-firmwares' && DataTableContextObj?.searchState?.selectedRowIds && DataTableContextObj?.searchState?.selectedRowIds.length > 1 ? "disabled" : "",
            icon: null,
            
            varient:"primary"
        },
    ]

    let steps = [
        {
            label:"Select Firmware"
        },
        {
            label:"Select Devices"
        },
        {
            label:"Schedule Campaign"
        }
    ]
    let currentProgress = (100 / steps.length) * activeStep;

    return <div className="page-wrapper">
    <PageHeader name=""  breadCrumbArr={breadCrumbArr}  pageButtons={pageButtons} countLabel={null} />
    { DataTableContextObj?.searchState?.name === 'fuota-campaign-firmwares' && DataTableContextObj?.searchState?.selectedRowIds && DataTableContextObj?.searchState?.selectedRowIds.length > 1 && <div className="alert alert-warning" role="alert">
        <FontAwesomeIcon icon={solidfaExclamationTriangle} />
        {strings.SELECT_FIRMWARE_WARNING_MESSAGE}
    </div>}

<div className="table-wrapper">
    <div className="stepper-main">
        
        <div className="d-flex justify-content-around">
            {steps.map((step, index) => {
                let stepClass = "bg-white"
                if(index == activeStep){
                    stepClass = "bg-black"
                } else if(index <= activeStep) {
                    stepClass = "step-primary"
                }
                return (
                    <div>
                        <span style={{fontWeight: "bold"}}>{step.label}</span>
                        <div className="d-flex justify-content-center">
                            <div className={`${stepClass} stepper-circle border-stepper-black rounded-circle`}>
                                </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <ProgressBar now={currentProgress} className="custom-progress bg-black" />
    </div>
    {activeStep == 0 && 
        
        <MyFirmwares ref={firmwareRef} />
    }
    {activeStep == 1 && 
        <SelectDevices ref={devicesRef} />
    }
    {activeStep == 2 && 
        <DetailPage />
    }
</div>
    </div>;
}

export default CampaignStepper;