import React, { /*useContext*/ } from "react";import { createRoot } from "react-dom/client";
//import AppContext from '../../context/AppContext'

import { Spinner } from 'react-bootstrap';
import { strings } from "../../services/Localization";

interface ReactSpinnerProps {
    isBig?: boolean;
}


const ReactSpinner : React.FC<ReactSpinnerProps> = React.memo(({isBig = false}) => {
    return (
        <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status" size={isBig ? undefined: 'sm'}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>    
        </div>
    )
})

export { ReactSpinner  }

const SpinnerHide = () => {

    //ReactDOM.unmountComponentAtNode(document.getElementById("react-spinner"))
    spinnerRootInstance && spinnerRootInstance._internalRoot && spinnerRootInstance.unmount()
}
  
export { SpinnerHide }
let spinnerRootInstance;

const SpinnerShow =  () => {

    let elem:any = [];
    elem = document.getElementsByClassName("react-spinner");
    if(elem) {
        for (var j = elem.length - 1; j >= 0; j--) {
            spinnerRootInstance = (spinnerRootInstance && spinnerRootInstance._internalRoot) ? spinnerRootInstance : createRoot(elem[j])
            spinnerRootInstance.render(<ReactSpinner />)
        };
    }

    //ReactDOM.render(<ReactSpinner />, document.getElementById("react-spinner"));

 }
  
 export { SpinnerShow }


 
const TotalRecordRender = (props) => {

    return (<small><span className="text-info">{strings.TABLE_COUNT_ALL}</span> ({props.totalRecords})</small>);
}

let totalRecordRootInstance;

const TotalRecordShow =  (totalRecords, countLabel, name) => {

    let id = (name) ? name + '-record-count' : 'data-table-record-count';
    if(countLabel && document.getElementById(id) != null) {
            totalRecordRootInstance = createRoot(document.getElementById(id))   
            totalRecordRootInstance.render(<TotalRecordRender totalRecords={totalRecords} countLabel={countLabel} />);
    }
    
 }
  
 export { TotalRecordShow }

 const TotalWarningMessageRender = (props) => {
    const {rowData} = props;
    return (
        <div className="alert alert-info" role="alert">
        <h4 className="alert-heading">Sorting has been disabled !</h4>
        <p className="mb-0">{`Listing only first ${rowData?.total} devices, as there are ${rowData?.recordTotal} in total. `}<br/>
            <small  className="font-italic">{`Device count last refreshed at ${new Date(rowData?.recordsTotalUpdateTime)?.toLocaleTimeString()}, Data last refreshed at ${new Date(rowData?.recordsFilteredUpdateTime)?.toLocaleTimeString()}`} </small></p>
        </div>
);
}

 const TotalWarningMessage = (rowData) => {
    const rootInstance = createRoot(document.getElementById("warning-message"))
    rootInstance.render(<TotalWarningMessageRender rowData={rowData} />)
 }
 

 export {TotalWarningMessage}