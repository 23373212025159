/**
 * This file contains function that are used to render an array.
 */

import React, { CSSProperties, ReactNode } from "react";
import { Accordion, Alert, Button, Card, Form, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { fa } from "./SchemaTextParser";
import { IElemNode, ISchemaModalProps, IUiSchemaElemArgs, updateValues } from "./SchemaController";
import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { evalString, IControl, IExprObjects, ISchemaLib } from "./SchemaTools";




export function embedArrayElement(key: string, element: ReactNode, rem: () => void, add: () => void) {
    // Override in extended class
    return (<tr className="schema-engine-array-element-container">
        <td>{element}</td>
        {(add || rem) && (<td>
            {add && <Button variant="outline-secondary" onClick={add} size="sm">
                        {fa("fa-add")}
            </Button>}
            {rem && <Button variant="outline-secondary" onClick={rem} size="sm">
                        {fa("fa-trash-alt")}
            </Button>}
        </td>)}
    </tr>);
}





export function embedArrayElementObject(args: IUiSchemaElemArgs, elements: ReactNode, type: "embox" | "table" | "accordion",
								   rem: () => void, add: () => void) {

    const {key, fullkey, self} = args;
    const addBut = add ? (
        <Button as="div" variant="outline-secondary" className="mr-1" onClick={(ev) => {ev.stopPropagation(); add(); }} size="sm">
            {fa("fa-add")}
        </Button>) : null;
    const remBut = rem ? (
        <Button as="div" variant="outline-secondary" onClick={(ev) => {ev.stopPropagation(); rem(); }} size="sm">
            {fa("fa-trash-alt")}
        </Button>) : null;

    const titleElem = args.title ? args.stringToComponent(args.title) : "#" + args.key;

    if (type === "embox") {

        return (<Card key={fullkey} className="schema-engine-array-element-object">
            <Card.Header>
                {titleElem}
                {addBut}
                {remBut}
            </Card.Header>
            <Card.Body>
                {elements}
            </Card.Body>
        </Card>);

    } else if (type === "accordion") {

        return (
            <Accordion.Item key={fullkey} eventKey={key} className="schema-engine-array-accordion-element-object">
                <Accordion.Header onClick={() => self.updateLayout(200)}>
                    <div className="d-flex justify-content-between" style={{width : ' inherit'}}>
                        <div>
                            {titleElem}
                        </div>
                        <div className="mx-2">
                            {addBut}
                            {remBut}
                        </div>
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    {elements}
                </Accordion.Body>
            </Accordion.Item>
        );

    } else if (type === "table") {

        const cols = (elements as JSX.Element[]).map((elem, idx) => <td key={"col" + idx}>{elem}</td>);
        if (addBut || remBut) { cols.push(<td key="addrow">{addBut}{remBut}</td>); }
        return (<tr key={fullkey}>{cols}</tr>);

    } else {

        return <div>Error, unexpected type</div>

    }
}






export function embedArrayContainer(args: IUiSchemaElemArgs, schema: IJsonSchemaObject,
                            elements: ReactNode, add: (() => void) | null, 
                            boxType: "embox" | "table" | "accordion" | "card") {

    const { title, fullkey, self } = args;
    const elems = schema.type === "object" 
        ? elements
        : <div className="px-2"><Table bordered={false} className="schema-engine-array-container-table"><tbody>{elements}</tbody></Table></div>

    if (boxType === "embox") {

        return (
            <Card key={fullkey} className="schema-engine-array-container">
                {title && <Card.Title>
                    {title}
                </Card.Title>}
                <Card.Body>
                    {elems}
                    {add && <Button variant="outline-secondary" onClick={add} size="sm">
                        {fa("fa-add")}
                    </Button>}
                </Card.Body>
            </Card>
        );

    } else if (boxType === "accordion") {

        let body: JSX.Element;

        if ((elements as JSX.Element[])?.length > 0) {
            const acckey = "/__acc_" + fullkey.replace(/[/]/g, ".");
                body = <Accordion
                className={"schema-engine-array-accordian-container " + (add ? "mb-2" : "mb-3")}
                activeKey={self.instantValues[acckey]}
                onSelect={k => updateValues(self, { values: { [acckey]: k }}, "", "" )}
            >
                {elems}
            </Accordion>

        } else {

            const placeholder = args.uiElem?.placeholder || "[[fa-brackets]] Empty";
            body = <Alert variant="light">{args.stringToComponent(placeholder)}</Alert>;
        }

        return (
            <div key={fullkey}>
                {body}

                {add && <Button className="mb-3" variant="outline-secondary" onClick={add} size="sm">
                    {fa("fa-add")}
                </Button>}
            </div>
        );

    } else if (boxType === "table") {

        const header: JSX.Element[] = [];

        const { lang } = self.props;
        const lib = self.lib;
        const { errors, values, oldValues, oldRootJsonSchema, rootCondJsonSchema, rootJsonSchema, jsonSchema, control } = self.objects;
        const readOnly = !!(control.readOnly || rootJsonSchema?.$uiSchema?.modal?.readOnly);
        const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, oldValues, control, uiSchema: rootJsonSchema.$uiSchema || {} };

        for (const eKey of Object.keys(schema?.properties || {})) {

            if (!schema.properties || !schema.properties[eKey]) { continue; }

            const efullkey = eKey;	// FIXME: add path

            const elem = schema.properties[eKey];
            const uiElem = elem?.$uiSchemaObject;

            const descLayout = "popup";
            const description = evalString(uiElem?.trust, (lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
                                            lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";
            const title       = evalString(uiElem?.trust, (lang && (elem as any)["title[" + lang + "]"]) || elem.title || eKey,
                                            lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";

            const titleElem = title ? args.stringToComponent(title) : null;
            const descElem  = description ? args.stringToComponent(description)  : null;
            const helpTip   = descLayout === "popup" && descElem ? <Tooltip>{descElem}</Tooltip> : null;
            const required = schema.required?.includes(eKey);

            header.push(<th key={eKey}>
                <Form.Label>
                    {titleElem}
                    {required && <span style={{color:"red"}}>*</span>}
                    {helpTip && <OverlayTrigger
                        placement="auto"
                        delay={{ show: 250, hide: 400 }}
                        trigger={["hover", "focus"]}
                        overlay={helpTip}
                    >
                        <span> {fa("fa-info-circle")}</span>
                    </OverlayTrigger>}
                </Form.Label>
            </th>);
        }


        return (
            <div>
                <Table key={fullkey} bordered={ false } className="schema-engine-array-container-table">
                    <thead><tr>{header}</tr></thead>
                    <tbody>{elements}</tbody>
                </Table>

                {add && <Button variant="outline-secondary" onClick={add} size="sm">
                        {fa("fa-add")}
                </Button>}
            </div>
        );

    } else {

        return <div/>

    }
}






/**
 * 
 * 
 * @param rows 
 * @param add 
 * @param args 
 * @param schema 
 * @param control 
 * @param thisObjects 
 * @param thisProps 
 * @param thisLib 
 * @returns 
 */

export function renderArrayTable(
			rows: ArrayRow[], 
			add: (() => void) | null,
	
			args: IUiSchemaElemArgs, 
			schema: IJsonSchemaObject,
			control: IControl,
			thisObjects: IExprObjects,
			thisProps: ISchemaModalProps,
			thisLib: ISchemaLib
) {

	const { fullkey } = args;

	const header: JSX.Element[] = [];

	const { lang } = thisProps;
	const lib = thisLib;
	const { errors, values, oldValues, oldRootJsonSchema, rootCondJsonSchema, rootJsonSchema, jsonSchema } = thisObjects;
	const readOnly = !!(control.readOnly || rootJsonSchema?.$uiSchema?.modal?.readOnly);
	const objects = { values, errors, oldRootJsonSchema, rootJsonSchema, rootCondJsonSchema, jsonSchema, oldValues, control, uiSchema: rootJsonSchema.$uiSchema || {} };

	const ctrlColStyle: CSSProperties = { width: "1%", "whiteSpace": "nowrap", "textAlign": "center" };


	let hasControls = false;
	for (const row of rows) {
		if (row.controls.add || row.controls.rem) {
			hasControls = true;
		}
	}

	const elements = rows.map((row, idx) => {
		const cols = row.elemNodes.map(e => <td key={e.args.fullkey}>{e.jsxElem}</td>);
		if (hasControls) {
			cols.push(<td key="row-controls" style={ctrlColStyle}>{makeArrayRowControlButtons(row)}</td>);
		}
		return <tr key={"row" + idx}>{cols}</tr>
	});


	for (const eKey of Object.keys(schema?.properties || {})) {

		if (!schema.properties || !schema.properties[eKey]) { continue; }

		const efullkey = eKey;	// FIXME: add path

		const elem = schema.properties[eKey];
		const uiElem = elem?.$uiSchemaObject;

		const descLayout = "popup";
		const description = evalString(uiElem?.trust, (lang && (elem as any)["description[" + lang + "]"]) || elem.description || "",
										lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";
		const title       = evalString(uiElem?.trust, (lang && (elem as any)["title[" + lang + "]"]) || elem.title || eKey,
										lib, objects, { fullkey: efullkey, readOnly, schema: elem }) + "";

		const titleElem = title ? args.stringToComponent(title) : null;
		const descElem  = description  ? args.stringToComponent(description)  : null;
		const helpTip   = descLayout === "popup" && descElem ? <Tooltip>{descElem}</Tooltip> : null;
		const required  = schema.required?.includes(eKey);

		header.push(<th key={eKey}>
			<Form.Label>
				{titleElem}
				{required && <span style={{color:"red"}}>*</span>}
				{helpTip && <OverlayTrigger
					placement="auto"
					delay={{ show: 250, hide: 400 }}
					trigger={["hover", "focus"]}
					overlay={helpTip}
				>
					<span> {fa("fa-info-circle")}</span>
				  </OverlayTrigger>}
			</Form.Label>
		</th>);
	}

	if (hasControls) {
		header.push(<th key="control-header-column"  style={ctrlColStyle}>{fa("fa-circle-ellipsis")}</th>)
	}

	return (
		<div>
			<Table key={fullkey} bordered={ false } className="schema-engine-array-container-table">
				<thead><tr>{header}</tr></thead>
				<tbody>{elements}</tbody>
			</Table>

			{add && <Button variant="outline-secondary" onClick={add} size="sm">
					{fa("fa-add")}
			</Button>}
		</div>
	);



}






/**
 * NOTE: this code is part of a transition from the old class based component to a new function based component. At the moment
 * some of the functionality here is replicated.
 * 
 * renderArrayTable take the ArrayRow[] data and generate a complete table layout version of the array.
 * 
 * TODO: correct the sorted list of properties.
 */

interface ArrayRowControls {
	add: () => void;
	rem: () => void;
	up?: () => void;
	down?: () => void;
}
export interface ArrayRow {
	elemNodes: IElemNode[];
	controls: ArrayRowControls;
}


function makeArrayRowControlButtons(row: ArrayRow) {

	const jsxButtons: ReactNode[] = [];

	if (row.controls.add) {
		jsxButtons.push(
			<Button key="add" variant="outline-secondary" onClick={row.controls.add} size="sm">
				{fa("fa-add")}
			</Button>
		)
	}
	if (row.controls.rem) {
		jsxButtons.push(
			<Button key="remove" variant="outline-secondary" onClick={row.controls.rem} size="sm">
				{fa("fa-trash-alt")}
			</Button>
		)
	}

	return jsxButtons;
}
