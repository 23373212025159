import React, { useContext }  from "react";

import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

import { isMobile } from 'react-device-detect';

import { ColumnType } from './DataTable/DataTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageButtonType } from "src/datatypes/datatypes";
import { DataTableContext } from "../Common/DataTable/DataTableState";

import { DataTableChildContext } from "../Common/DataTable/DataTableChildState";
import { strings } from "../../services/Localization";


interface pageButtonProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    scope?: string;
    dataTableView?: string;
}

const PageButtonsComponent: React.FC<pageButtonProps> = ({ pageButtons, columns, scope, dataTableView }) => {

    if(isMobile) {
        return <RenderPageButtonSmallScreen pageButtons={pageButtons} columns={columns} />;
    }else {
        return <RenderPageButton pageButtons={pageButtons}  columns={columns} scope={scope} dataTableView={dataTableView} />
    }

}

export { PageButtonsComponent }

// FIXME: fix the template

const RenderPageButton: React.FC<any> = ({ pageButtons, columns, scope, dataTableView }) => {

    const buttonList = pageButtons.filter(pb => pb.visible ? pb.visible() : true).map(function(pageButton, index) {
        
        if (pageButton.type === 'button_with_group') {
            const buttonGroupArr = pageButton?.title?.split(',')
            const iconGroupArr = pageButton?.iconGroup || []
            
            return (
              <div key={index} style={{ marginLeft: "4px", marginBottom: "1px", float: "left" }}>
                <ButtonGroup key={`buttonGroup-${index}`} aria-label="Basic example" size="sm">
                    { buttonGroupArr.map((buttonGroup, index) => {
                        
                        return (
                        <Button
                            variant="outline-dark"
                            key={`button-${buttonGroup}-${index}`}
                            className="text-nowrap"
                            size="sm"
                            onClick={() => pageButton.actionView(buttonGroup)}
                            active={ buttonGroup == dataTableView }
                        >{iconGroupArr[index] && <FontAwesomeIcon key={`icon-${buttonGroup}-${index}`} className="" icon={iconGroupArr[index]} />}<span key={`span-${buttonGroup}-${index}`} className="d-none d-sm-inline  ms-2">{`${buttonGroup}`}</span></Button>)
                        })}
                </ButtonGroup>
              </div>
            );
        } else if(pageButton.type === 'button_with_icon') {
            return (
                <div key={index} style={{marginLeft: "4px", marginBottom: "1px", float:'left'}}>
                    <Button size='sm' className= {pageButton.className ? pageButton.className : "text-nowrap"} title={pageButton.title} variant="dark" key={index} onClick={pageButton.action}>
                    {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />}
                        {pageButton.title}
                    </Button>
                </div>
            );

        }else if(pageButton.type === 'button') {
            return (
                <div key={index} style={{marginLeft: "4px", marginBottom: "1px", float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} key={index} variant="outline-dark" onClick={pageButton.action}>
                    {pageButton.icon &&  <FontAwesomeIcon icon={pageButton.icon} />}
                    </Button>
                </div>
            );

        } else if(pageButton.type === 'button_with_text') {
            
            return (
                <div key={index} style={{marginLeft: "4px", marginBottom: "1px", float:'left'}}>
                    <Button size='sm' className="text-nowrap" title={pageButton.title} key={index} variant="outline-dark" onClick={pageButton.action}>
                        {pageButton.title}
                    </Button>
                </div>
            );

        } else if(pageButton.type === 'column') {

            return ( <RenderColumnsButtons columns={columns} key={index} scope={scope}/>)

        }else if(pageButton.type === 'dropdown_only_icon') {
            
            return ( <RenderDropDownButton pageButton={pageButton}  key={index}  />)

        }else {
            return ( <RenderDropDownButton pageButton={pageButton}  key={index}/>)
        }
    })
    
    return buttonList;
}


interface RenderPageButtonSmallScreenProps {
    pageButtons: PageButtonType[];
    columns?:ColumnType[];
    
}

const RenderPageButtonSmallScreen: React.FC<RenderPageButtonSmallScreenProps> = ({ pageButtons }) => {

    const buttonList = pageButtons.map(function(pageButton, index) {
        if(pageButton.type === 'button_with_icon' || pageButton.type === 'button') {
            return (
                <Dropdown.Item key={index} onClick={pageButton.action}> {pageButton.icon &&  <FontAwesomeIcon className="mr-2" icon={pageButton.icon} />} {pageButton.title}</Dropdown.Item>
            );
        } else if( pageButton.type === 'button_with_text') {
            return (
                <Dropdown.Item key={index} onClick={pageButton.action}>{

                    isMobile &&  <FontAwesomeIcon className="mr-2 invisible" icon={pageButton.icon} />}
                        {pageButton.title}
                </Dropdown.Item>
            );
        }else {
            return null;
        }

    })


    const dropDownButtonList = pageButtons.map(function(pageButton, index) {

        if(pageButton.type !== 'button') {
            return (
                <div  key={index} style={{marginRight:'1px'}}>
                    <RenderDropDownButton pageButton={pageButton} />
                </div>
            );
        }else {
            return null;
        }

    })

    return (
            <div style={{display:'flex', flexDirection: 'row'}}>
                <div style={{margin:'2px'}}>
                    <Dropdown>
                        <Dropdown.Toggle  size='sm' variant="outline-dark" id="dropdown-basic">
                            More
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {buttonList}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div>
                    {dropDownButtonList}
                </div>
            </div>
            )
}


interface RenderColumnsButtonsProps {
    columns:  ColumnType[];
    setSearchState?:(prevSearchStates: any) => void;
    scope?:string;
}

const RenderColumnsButtons: React.FC<RenderColumnsButtonsProps> = ({ columns, setSearchState, scope }) => {
    
    //const [fields, setFields] = useState([]);
    let DataTableContextObj;
    if(scope === 'child') {
        DataTableContextObj = useContext(DataTableChildContext);
    }else {
        DataTableContextObj = useContext(DataTableContext);
    }

    let visibleColumns = DataTableContextObj.searchState.visibleColumns

    function pxToNumber(px='0px') {
        return parseInt(px.replace("px", "") || '0');
    }

    const toggleHideShow = ( columnKey ) => {

        let pos = visibleColumns.indexOf(columnKey);
        const prevDataTableOptions = DataTableContextObj.searchState?.dataTableOption
        let dataTableOptionColumns = prevDataTableOptions?.columns;

        if (prevDataTableOptions.resizeWidth &&
            prevDataTableOptions.resizeWidth.length > 0 &&
            dataTableOptionColumns &&
            dataTableOptionColumns.length > 0) {
            const dataTableWidthContainer = document.getElementById('ob-data-table-main-container');
            const totalDataTableWidth = dataTableOptionColumns.reduce((total, col) => total + pxToNumber(col.newCellWidth), 0);
          
            dataTableOptionColumns.forEach((column) => {
              if (column.key === columnKey) {
                const newCellWidth = pxToNumber(column.newCellWidth);
                const adjustment = newCellWidth / prevDataTableOptions.resizeWidth.length;
          
                dataTableOptionColumns.forEach((col) => {
                  if (prevDataTableOptions.resizeWidth.includes(col.key)) {
                    let widthToBeAdded = pxToNumber(col.newCellWidth);
          
                    if (pos > -1 && dataTableWidthContainer) {
                      if (totalDataTableWidth < dataTableWidthContainer.offsetWidth) {
                        widthToBeAdded += adjustment;
                      } else {
                        widthToBeAdded -= adjustment;
                        if (widthToBeAdded < 100) widthToBeAdded = 100;
                      }
                    } else {
                      widthToBeAdded -= adjustment;
                      if (widthToBeAdded < 200) widthToBeAdded = 200;
                    }
          
                    col.newCellWidth = `${widthToBeAdded}px`;
                  }
                });
              }
            });
          }


        if(pos > -1) {
            if(visibleColumns.length > 1) {
                
                visibleColumns.splice(pos, 1);

                DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                    return  {...prevSearchState, visibleColumns:[...visibleColumns],  dataTableOption:{...prevDataTableOptions, columns:dataTableOptionColumns }};
                })

            }
        }else {

            DataTableContextObj.setSearchState((prevSearchState:any ) => { 
                return  {...prevSearchState, visibleColumns:[...visibleColumns, columnKey ]};
            })

        }
    }
    if(!columns) {
        return null;
    }
  
    const filteredColumn = columns.filter((item) => {
        return (item.key !== 'bulk_action_checkbox' && item.key !== 'action_button' && item.hasOwnProperty('enabledInColumn') && !item.doNotShowInColumnDropDown)
    })

    return (
        <Dropdown>
            <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic" style={{marginLeft: '4px'}}>
                {strings.COLUMNS}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {filteredColumn.map(function(item, index) {
                    let checked = (visibleColumns.indexOf(item.key) > -1) ? true : false;
                    return (<div style={{}}  key={index} >
                            <div style={{display: 'flex',  flexDirection: 'row'}}>
                                <div style={{margin: 5}}>
                                    <input type="checkbox" name={item.key} value={item.key} checked={checked} onChange={() => { toggleHideShow(item.key)}} />
                                </div>
                                <div style={{margin: 5}}>
                                    {item.title}
                                </div>
                            </div>
                    </div>);
                    
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}

interface RenderDropDownButtonProps {
    pageButton: PageButtonType;
    
}


const RenderDropDownButton: React.FC<RenderDropDownButtonProps> = ( props ) => {

    if(!props.pageButton.dropDownItems) return null;

    return (
        <div  style={{marginLeft: "4px", marginBottom: "1px", float:'left'}}>
            <Dropdown className={props.pageButton.className ? props.pageButton.className : ''}>
                <Dropdown.Toggle size='sm' variant="outline-dark" id="dropdown-basic" title={props.pageButton.title}>
                    {props.pageButton.type === 'dropdown_only_icon' &&  <FontAwesomeIcon icon={props.pageButton.icon} />}
                    {props.pageButton.type === 'dropdown' && props.pageButton.title}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {props.pageButton.dropDownItems.map(function(item, index) {
                        if (!Object.keys(item).includes("visible") || item.visible) {
                            return (<Dropdown.Item onClick={ () => { item.action() } } key={index}>{item.icon && <FontAwesomeIcon icon={item.icon} style={ item.style ? item.style : { marginRight: '8px' }} />}{item.title}</Dropdown.Item>)  
                        }  else { 
                            return null
                        }
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

