import React, { useEffect, useState, useContext } from "react";
import { strings } from "../../services/Localization";
import { checkLogin } from "../../services/Login";
import { useNavigate, useParams } from 'react-router-dom';
import { IUser } from "src/dassTypes";
import {  dialog, dialogDescription } from '../../components/Common';
import {  TAB_ADD_RULE, TAB_RULE_INFO } from '../../datatypes/tabsconstants'
import AppContext from '../../context/AppContext'
import { BreadCrumbType, PageButtonType } from '../../datatypes/datatypes';
import { ActionType, BulkActionType, ColumnType, DataTableOption  } from '../../components/Common/DataTable/DataTypes';
import { getAppBase } from '../../utils/consts';
import { GenericDassQuery } from "../../services/BasicDassQueries";
import { toast } from "../../utils/Toaster";
import { getVisibleActions, actionIcon } from "../../utils/filters";
import{ ID_INPUT_VALIDATION, DEFAULT_RECORD_LIMIT, DEFAULT_INPUT_VALIDATION }  from "../../components/Common/DataTable/DataTableConsts";
import PageContent from "../PageContent";
import RenderTagsOverlay from "../../components/Common/RenderTagsOverlay";
import { isMobile } from "react-device-detect";

import { faTrashCan,  faAdd, faRefresh, faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { deviceData, emptyDeviceData } from "../../components/Common/DataTable/DataTableState";


interface IRulesStates {
    loggedUser: IUser | null;
    showAlertModal: boolean;
    editUserId: string;
    pageTitle: string;
    breadCrumbArr: BreadCrumbType[];
    refresh:boolean;
    isRowDeleted?:boolean;
}

interface IRowType {

  ruleUuid: string;
  name: string;
  description: string;
  is_active: boolean;
  creation_time: string;
  last_modified_time: string;
  groups: string;
  tags: string;
  conditionType: string;
  timeRangeSeconds: number;
  direction: string;
  exactValue: number;
}


const Rules: React.FC<{}> = () => {

    const AppContextObj = useContext(AppContext);
    const navigate = useNavigate();
    let { id, tabname } = useParams();
    const stateInit = {
        loggedUser: AppContextObj.user,
        pageTitle: strings.NAV_MANAGE_RULES,
        showAlertModal: false,
        editUserId: '',
        breadCrumbArr: [{label: strings.NAV_ALERTS, url:''}, {label: strings.NAV_MANAGE_RULES, url:''}],
        refresh:false,
        isRowDeleted:false
    };

    const [ruleState, setRuleState] = useState<IRulesStates>(stateInit)

    const deviceProfileFetcher = async () => {

        if(!deviceData['deviceProfiles']) {
            const deviceprofileData  = await GenericDassQuery(`/rest/device-profiles?show_region=true`, { method: "GET" });
            deviceData['deviceProfiles'] = deviceprofileData.data.map(ele => { return { label : ele.profile_name,  value: ele.profile_uuid }})
        }
        return deviceData['deviceProfiles'];
    }

    const alarmDefinitionFetcher = async () => {

        if(!deviceData['alarmDefinitions']) {
            const alarmDefinitionsData  = await GenericDassQuery(`/rest/alarm-definitions`, { method: "GET" });
            deviceData['alarmDefinitions'] = alarmDefinitionsData.data.map(ele => { return { label : ele.name,  value: ele.alarmDefinitionUuid }})
        }
        return deviceData['alarmDefinitions'];
    }

    useEffect(() => {

        emptyDeviceData();

        if(checkLogin(AppContextObj.user)) {
            setRuleState(prevState => { return {...prevState, loggedUser: AppContextObj.user }})
        }
        deviceProfileFetcher()
        alarmDefinitionFetcher()

    },[AppContextObj.user?.userid, ruleState.refresh])

     const detailPageNav = ( navigate, tab, name, row) => {
        
        navigate(`${getAppBase()}/rules/${name}/${tab}`, {state: {row: row, prevPageUrl: `${getAppBase()}/rules`}})
    }

    const detailPageAddNav = ( navigate, tab) => {
        
        navigate(`${getAppBase()}/rules/${tab}`, {state: {prevPageUrl: `${getAppBase()}/rules`}})
    }


   const getActions = () => {

        let actions: ActionType[] = [
        {
            type: "action",
            text: strings.DELETE_RULE,
            visible: () => (AppContextObj?.user?.can_delete_groups && !AppContextObj?.user?._readonly),
            render: (row, title) => actionIcon(row.ruleUuid, title, faTrashAlt.iconName),
            action: (group) => deleteRule(group)
        }]


        const bulkActions: BulkActionType<IRowType>[] = [
          {
              type: "header",
              text: "Default Actions"
          },
          {
              type: "action",
              text: strings.DELETE_RULES,
              render: (row, title) => actionIcon(row.ruleUuid, title, faTrashCan.iconName),
              action: (selectedIds) => deleteRuleInBulk(selectedIds),
              visible: () => { 
                  return AppContextObj.user?.account_status === "active" 
                         && !AppContextObj.user?._readonly
                         &&  AppContextObj.user?.can_register
              } 
          }];

        return {
            actions: actions,
            bulkActions:bulkActions
        }
        
    }


    const deleteRuleInBulk =  async (selectedRows: IRowType[]) => {
        const names = selectedRows.map((row) => {
            return row['name'];
        })
        const ruleUuids = selectedRows.map((row) => {
            return row['ruleUuid'];
        })
        if(ruleUuids && ruleUuids.length > 0) {
            const confirmDialogeSettings = {
                title:  strings.BULK_DELETE_CONFIRM_DIALOAG,
                description: await dialogDescription(names) + "",
                actionLabel: strings.ACTION_DELETE,
            };

            if (await dialog(confirmDialogeSettings) === true) {
                try {
                    Promise.all(ruleUuids.map((ruleUuid) => {
                        return GenericDassQuery("/rest/rules/" + ruleUuid, { method: "DELETE" });
                    })).then((values) => {
                        refreshTable(true);
                        toast.success(strings.BULK_DELETE_RULE_SUCCESS_MESSAGE);
                    });
                } catch (e) {
                    toast.error(e.message);
                }
            }
        }
    }


   const deleteRule =  async (rule) => {
        const confirmDialogeSettings = {
            title:  strings.DELETE_CONFIRM_DIALOG,
            description: rule.name,
            actionLabel: strings.ACTION_DELETE,
        };

        if (await dialog(confirmDialogeSettings) === true) {
            try {

                Promise.resolve(GenericDassQuery("/rest/rules/" + rule.ruleUuid, { method: "DELETE" })).then((values) => {

                    refreshTable(true);
                    toast.success(strings.DELETE_RULE_SUCCESS_MESSAGE);

                });
               
            } catch (e) {
                toast.error(e.message);
            }
        }
    }
  





   const initDataTable = () => {
        
        const {actions, bulkActions} = getActions();

        const appBase = getAppBase();
        const tagsFetcher = async () => {
            try {

                let pageUrl = `/rest/tags`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const tags =  response.data;
                let tagOptions = tags.map((tag) => {
                    return {label: tag.tagid, value: tag.tagid}
                })
                return tagOptions;

            } catch(e) {
                console.log(e);
            }
        }

        const groupFetcher = async () => {
            try {

                let pageUrl = `/rest/groups`;
                let response  = await GenericDassQuery(pageUrl, { method: "GET" });
                const groups =  response.data;
                let groupOptions = groups.map((tag) => {
                    return {label: tag.groupid, value: tag.groupid}
                })
                return groupOptions;

            } catch(e) {
                console.log(e);
            }
        }
        
        const renderDeviceProfile = (deviceUuids: string[]) => {
            let profiles = "";
            try{
                for(let id of deviceUuids) {
                    if(deviceData['deviceProfiles'] && deviceData['deviceProfiles'].length > 0){
                        const findIndex = deviceData['deviceProfiles'].findIndex((res) => res.value == id);
                        if(findIndex != -1){
                            if(!profiles) {
                                profiles = profiles + deviceData['deviceProfiles'][findIndex].label
                            } else {
                                profiles = profiles + ',' + deviceData['deviceProfiles'][findIndex].label
                            }
                            
                        } 
                    }    
                }
                return RenderTagsOverlay(profiles, 'gray', null, null, "Device Profiles", '140px')
                //                 return RenderTagsOverlay(profiles, 'gray', `${appBase}/profiles/device/~tag~/edit-profile`, {prevPageUrl:`${getAppBase()}/rules`,row: {  device_profile_id:0 },}, "Device Profiles", '100px')
                
            } catch(e){
                console.log("e",e)
            }        
            return profiles
        }

        const renderAlarmDefinition = (alarmDefinitionUuids: string[]) => {
            let alarmDefinitions = "";
            try{
                for(let id of alarmDefinitionUuids) {
                    if(deviceData['alarmDefinitions'] && deviceData['alarmDefinitions'].length > 0){
                        const findIndex = deviceData['alarmDefinitions'].findIndex((res) => res.value == id);
                        if(findIndex != -1){
                            if(!alarmDefinitions) {
                                alarmDefinitions = alarmDefinitions + deviceData['alarmDefinitions'][findIndex].label
                            } else {
                                alarmDefinitions = alarmDefinitions + ',' + deviceData['alarmDefinitions'][findIndex].label
                            }
                            
                        } 
                    }    
                }
                return RenderTagsOverlay(alarmDefinitions, 'gray', null, null, "Alarm Definitions", '140px')
                //                 return RenderTagsOverlay(profiles, 'gray', `${appBase}/profiles/device/~tag~/edit-profile`, {prevPageUrl:`${getAppBase()}/rules`,row: {  device_profile_id:0 },}, "Device Profiles", '100px')
                
            } catch(e){
                console.log("e",e)
            }        
            return alarmDefinitions
        }

        const columns: ColumnType[] = [
            {
                key: 'bulk_action_checkbox',
                type: "bulk_action_checkbox",
                title: 'Bulk Action',
                filterable: false,
                cellWidth: 3,
                newCellWidth: "30px",
                customClass: 'sticky left-first',
            },

            {
                key: "name",
                type: "text",
                title: strings.RULE_NAME,
                filterable: true,
                filterField: 'search_name',
                filterType: 'text',
                sortKey: "sort_by_name",
                sortable: true,
                inputValidation: ID_INPUT_VALIDATION,
                filterParams: {
                    mapper: (x) => x || undefined
                },

                detailLink: true,
                detailPageNav: (row) =>  detailPageNav(navigate, TAB_RULE_INFO, row.ruleUuid, row ),
                newCellWidth: isMobile ? '250px' : "300px",
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.description
            },    {
                key: "description",
                title: strings.DESCRIPTION,
                type: "text",
                filterable: true,
                filterField: "search_description",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: true,
                sortKey: "sort_by_description",
                extraClass: 'ow-datatable-overflow-ellipsis',
                filterParams: {},
                cellWidth: 50,
                dataAlign:'left',
                newCellWidth: '300px',
                render_tooltip: (row) => row.description
            }, 

            {
                key: "kind",
                title: strings.CONDITION_TYPE,
                type: "text",
                filterable: false,
                filterField: "search_kind",
                filterType: "text",
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                sortKey: "sort_by_kind",
                filterParams: {},
                cellWidth: 50,
                dataAlign:'left',
                newCellWidth: "150px",
                extraClass: 'ow-datatable-overflow-ellipsis',
                render_tooltip: (row) => row.kind
            }, 
            {
                key: "alarm_definitions",
                title: strings.ALARM_DEF_NAME,
                type: "text",
                filterField: 'search_alarm_definition_uuid',
                filterable: true,
                filterType: "multiselect",
                extraClass: 'd-flex align-items-center',
                filterParams: {
                    optionFetcher: alarmDefinitionFetcher,
                    data: [],
                    mapper: x => x,
                },
                inputValidation: DEFAULT_INPUT_VALIDATION,
                sortable: false,
                sortKey: "sort_by_alarm",
                cellWidth: 50,
                dataAlign:'left',
                newCellWidth: "150px",
                render: x => { return renderAlarmDefinition(x.alarm_definitions || '') },
            }, 
            {
                key: "is_active",
                title: strings.IS_ACTIVE,
                type: "boolean",
                filterField: 'search_is_active',
                filterable: true,
                filterType: 'select',
                newCellWidth: "100px",
                filterWidth: "65px",
                filterParams: {
                    options:[
                        {label:strings.YES, value: "true"}
                        ,{label:strings.NO, value: "false"}
                    ]
                },
                render: (row) => row.is_active ? strings.YES : strings.NO,
            },
            {
				key: "profiles",
				title: strings.DEVICE_PROFILE,
				type: "text",
				newCellWidth: "190px",
                render: x => { return renderDeviceProfile(x.profiles || '') },
                // customNavigation: () => {}, //blank customNavigation will stop whole row clickable behavior so tag clicking can work
                customNavigation: (row) => {
                    if(row.profiles){return null} else {
                        detailPageNav(navigate, TAB_RULE_INFO, row.ruleUuid, row );
                }},
                filterField: 'search_device_profile_uuid',
                filterable: true,
                filterType: "multiselect",
                extraClass: 'd-flex align-items-center',
                filterParams: {
                    optionFetcher: deviceProfileFetcher,
                    data: [],
                    mapper: x => x,
                },
			},
            {
                key: "tags",
                title: "Tags", // FIXME Add string.xx
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                render: x => { return RenderTagsOverlay(x.tags?.join(','), "gray", `${appBase}/tags/~tag~/edit-tag`, {prevPageUrl:`${getAppBase()}/rules`,row: { tagid:0 },}, "Tags", "140px") },
                customNavigation: (row) => {
                    if(row.tags){ return null } 
                    else { detailPageNav(navigate, TAB_RULE_INFO, row.ruleUuid, row );}
                },
                filterable: true,
                filterField: "search_tags",
                filterType: "multiselect",         
                extraClass: 'd-flex align-items-center',        
                newCellWidth: "150px",
                filterParams: {
                    optionFetcher: tagsFetcher,
                    data: [],
                    mapper: x => x,
                }
            },
            {
                key: "groups",
                title: strings.MY_DEVICES_GROUPS,
                type: "text_with_tooltip",
                cellWidth: 25,
                cellWidthType: '%',
                dataAlign: "left",
                render: x => { return RenderTagsOverlay(x.groups?.join(','), 'gray', `${appBase}/groups/~tag~/group-info`, {prevPageUrl:`${getAppBase()}/rules`,row: { groupid:0 },}, "Groups", '140px') },
                customNavigation: (row) => {
                    if(row.groups){return null} else {
                        detailPageNav(navigate, TAB_RULE_INFO, row.ruleUuid, row );
                }},
                filterable: true,
                filterField: "group",
                filterType: "multiselect",        
                extraClass: 'd-flex align-items-center',        
                newCellWidth: "150px",
                filterParams: {
                    optionFetcher: groupFetcher,
                    data: [],
                    mapper: x => x,
                }
            }
        ];

        columns.push({
            key: 'action_button',
            type: "action_button",
            title: 'Actions',
            filterable: false,
            cellWidth: 3,
            newCellWidth: "140px",
            customClass: 'sticky right',
        });


        const options:DataTableOption<IRowType> = {
            
            url:'/uiapi/rest/rules',
            query_param:{ all:true, get_pages:true, limit:DEFAULT_RECORD_LIMIT, stream:'progress'},
            serial_number:false,
            id_field:'name',
            oboe_path:'pages.*',
            available_key: 'name',
            modal:false, ///pass true to render single select radio buttons in palce of checkbox
            columns,
            actions: actions,
            defaultSortField: 'sort_by_name',
            defaultSortOrder: 'asc',
            emptyDataMsg: strings.NO_RULE_AVAILABLE,
            bulkActions: getVisibleActions(bulkActions)

        }

        return options;
    }
    
 

    const refreshTable = (isRowDeleted=false) => {

        setRuleState(prevState => {
            return {...prevState, refresh:!prevState.refresh,isRowDeleted:isRowDeleted}
        })
        
    }
    
   const getPageButtons = () => {
        
        const pageButtons: PageButtonType[] = [
            {
                title: strings.ADD_RULE,
                action: () => detailPageAddNav(navigate, TAB_ADD_RULE ),
                type: 'button_with_icon',
                icon: faAdd,
                visible: () => { return AppContextObj?.user && AppContextObj?.user?.can_create_rules && !AppContextObj?.user?._readonly }
            },
            {
              
                title: strings.REFRESH_LIST,
                action: () => { refreshTable() },
                type: 'button',
                icon: faRefresh
            }
        ];

        return pageButtons;
    }


    return (
        <PageContent
            name="my-rules" 
            id={id} 
            tabname={tabname} 
            actions={getActions()} 
            breadCrumbArr={ruleState.breadCrumbArr} 
            pageButtons={getPageButtons()} 
            countLabel={strings.NAV_MANAGE_RULES} 
            dataTableOption={initDataTable()} 
            isRowDeleted={ruleState.isRowDeleted}
            refresh={ruleState.refresh}>
        </PageContent>
    );

}


export default Rules;
