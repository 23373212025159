
import { ReactNode } from "react";
import { IUiSchemaCardArgs, IUiSchemaElemArgs } from "./SchemaController";
import { ISchemaFormProps } from "./SchemaForm";
import { ISchemaLayoutComponentProps, ISchemaLayoutComponentRef } from "./SchemaLayout";
import { IJsonSchemaObject } from "../UiJsonSchemaTypes";
import { cloneObject } from "./SchemaTools";



type ComponentHandler = (args: IUiSchemaElemArgs) => ReactNode;
type CardHandler = (args: IUiSchemaCardArgs, children: ReactNode) => ReactNode;
type FormComponent = React.FC<ISchemaFormProps>;
type SchemaLayoutComponent = React.ForwardRefExoticComponent<ISchemaLayoutComponentProps & React.RefAttributes<ISchemaLayoutComponentRef>>;
type TextMarkerHandler = (key: string, style: any) => ReactNode;

export interface IComponentHandlers {
	[name: string]: ComponentHandler;
}
export interface ICardHandlers {
	[name: string]: CardHandler;
}
export interface IFormComponents {
	[name: string]: FormComponent;
}
export interface ITextMarkerHandlers {
	[name: string]: TextMarkerHandler;
}



export const registeredJsonSchemas: {
	uri: string;
	fileMatch: string[];
	schema: any;
}[] = [];
export const registeredExtensionLib = {};
export const registeredExtensionHandlers: IComponentHandlers = {};
export const registeredExtensionComponents: { [component: string]: React.FC<any> } = {};
export const registeredExtensionCardHandlers: ICardHandlers = {};
export const registeredExtensionFormsComponents: { [component: string]: FormComponent } = {};
export const registeredExtensionTextMarkerHandlers: ITextMarkerHandlers = {};
export const registeredLayoutComponents: { [component: string]: SchemaLayoutComponent } = {};

export function registerComponentHandler(type: string, handler: ComponentHandler) {
	registeredExtensionHandlers[type] = handler;
}
export function registerExtensionComponent(type: string, component: any) {
	registeredExtensionComponents[type] = component;
}
export function registerExtensionLibFunction(name: string, func: (...args: any) => any) {
	registeredExtensionLib[name] = func;
}
export function registerExtensionCardHandler(name: string, func: CardHandler) {
	registeredExtensionCardHandlers[name] = func;
}
export function registerExtensionFormComponent(name: string, func: FormComponent) {
	registeredExtensionFormsComponents[name] = func;
}
export function registerExtensionTextMarkerHandler(name: string, func: TextMarkerHandler) {
	registeredExtensionTextMarkerHandlers[name] = func;
}
export function registerExtensionLayoutComponent(name: string, func: SchemaLayoutComponent) {
	registeredLayoutComponents[name] = func;
}
export function registerJsonSchema(uri: string, fileMatch: string[], schema: any) {
	registeredJsonSchemas.push({ uri, fileMatch, schema: fixMarkdownDescription(cloneObject(schema)) });
}



/**
 * Function to convert all description fields that has the [[@md]] prefix from a normal description
 * to the VSCode proprietary markdownDescription field.
 * 
 * @param schema 
 * @returns 
 */
export function fixMarkdownDescription(schema: IJsonSchemaObject) {
	if (schema.description?.startsWith("[[@md]]")) {
		schema["markdownDescription"] = schema.description.substring(7);
		delete schema.description;
	}
	if (schema.properties) {
		for (const key of Object.keys(schema.properties)) {
			fixMarkdownDescription(schema.properties[key]);
		}
	}
	if (schema.$defs) {
		for (const key of Object.keys(schema.$defs)) {
			fixMarkdownDescription(schema.$defs[key]);
		}
	}
	if (schema.items) {
		fixMarkdownDescription(schema.items);
	}
	for (const elem of schema.oneOf || []) {
		fixMarkdownDescription(elem);
	}
	for (const elem of schema.anyOf || []) {
		fixMarkdownDescription(elem);
	}
	for (const elem of schema.allOf || []) {
		fixMarkdownDescription(elem);
	}

	return schema;
}